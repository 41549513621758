import "./info-item.styles.scss";

const InfoItem = ({label, value}) => (
    <li className="list-group-item">
        <div className="row">
            <div className="col-sm-5 col-md-4">
                <strong className="text-muted">{label}</strong>
            </div>
            <div className="col-sm-7 col-md-8">
                {value || <span className="text-muted">Not provided</span>}
            </div>
        </div>
    </li>
);

export default InfoItem;
