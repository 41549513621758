// React imports
import {faInfoCircle, faTrash} from "@fortawesome/free-solid-svg-icons";

// Icon imports
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {useEffect, useRef, useState} from 'react';

// React Bootstrap imports
import {Card, FormGroup, Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";

// Other imports
import {BsSendCheckFill} from "react-icons/bs";
import {FaFileSignature} from "react-icons/fa6";
import {PiSignatureBold} from "react-icons/pi";
import {TbFileCheck} from "react-icons/tb";

// React Router imports
import {useHistory, useLocation, useParams} from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas';
import toastr from "toastr";

// Component imports
import Header from "../../components/headerBlue/header.component";
import {Spinner} from "../../components/spinner/spinner.component";
import FileUploader from "../../components/uploader/file.uploader";
import {darceyOliverFont} from '../../fonts';
import {logoBase64, signatureBase64} from "../../logos";
import {IndemnityContract} from "./indemnity-contract";
import '../../DarceyOliver.otf';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["DarceyOliver.otf"] = darceyOliverFont;

pdfMake.fonts = {
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }, DarceyOliver: {
        normal: 'DarceyOliver.otf'
    }
}

const EnrollmentFormPage = () => {
    const {id} = useParams();
    const location = useLocation();
    const history = useHistory();

    const [files, setFiles] = useState([]);
    const [enrollment, setEnrollment] = useState(null);
    const [policyName, setPolicyName] = useState('');
    const [selectedPlan, setSelectedPlan] = useState("hsa");
    const [adminFee, setAdminFee] = useState(0);
    const [saving, setSaving] = useState(false);
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showSendModal, setShowSendModal] = useState(false);
    const [error, setError] = useState(null);
    const [canSubmit, setCanSubmit] = useState(false);

    const [mode, setMode] = useState('default');
    const [isAccepted, setIsAccepted] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [subscriber, setSubscriber] = useState('');
    const [isSigned, setIsSigned] = useState(false);
    const [signature, setSignature] = useState('');
    const [showSignatureModal, setShowSignatureModal] = useState(false);
    const sigCanvas = useRef({});
    const [imageURL, setImageURL] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);

    const [companyInfo, setCompanyInfo] = useState({
        companyName: "",
        dba: "",
        address1: "",
        address2: "",
        city: "",
        province: "",
        companyPostal: "",
        brokerName: "",
        brokerEmail: "",
        companyNotes: "",
        eligibleLifestyleExpenses: [],
        adminFee: 0
    });

    const [clientFirstName, setClientFirstName] = useState("");
    const [clientLastName, setClientLastName] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [companyPostal, setPostalCode] = useState("");
    const [bankPostal, setBankPostalCode] = useState("");
    const [isValidCompanyPostalCode, setIsValidCompanyPostalCode] = useState(true);
    const [isValidBankPostalCode, setIsValidBankPostalCode] = useState(true);
    const [isPostalCodeTouched, setIsPostalCodeTouched] = useState(false);

    const [categories, setCategories] = useState([{
        categoryName: "",
        jobDescription: "",
        limitSingle: "",
        limitCouple: "",
        limitFamily: "",
        waitingPeriod: "0",
        prorateExistingEmployees: false,
        prorateNewHires: false,
        coverageRate: 100,
        flexSelectionDate: "",
        isCustom: false,
        allowLsaDependents: false,
        hasHsa: false,
        hasLsa: false,
        hasRsp: false,
        flexDeadlineDate: ""
    }]);

    const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL_PROD : process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LOCAL;

    const predefinedDescriptions = {
        'Executive': 'e.g., Has the authority to enter into contracts on behalf of the company and is responsible for the overall direction and vision.',
        'Manager': 'e.g., Is responsible for all hiring and supervision of employees within their areas of responsibility.',
        'All Employees': 'e.g., All eligible employees, performing daily operational duties as directed.',
        'Full Time': 'e.g., Performs daily operational duties and works for at least 30 hours a week.',
        'Part Time': 'e.g., Performs daily operational duties and works for less than 30 hours a week.',
        'Admin Only': 'e.g., Plan admins only. No limit assigned.'
    };

    const lifestyleOptions = [
        "Automobile Expenses - Costs associated with vehicle operation and maintenance",
        "Computer Products/Mobile Devices - Products for personal computing and mobile communication",
        "Cosmetic procedures - Services related to personal appearance",
        "Covid-19 Support - Items or services to help with social distancing",
        "Education and personal development - Supports continuous learning",
        "Electronics - Technological products used to enhance leisure time",
        "Family Assistance - Attendant care and facility costs",
        "Family Leisure Fees - Participation in leisure activities outside of the home",
        "Fitness Apparel - Specialized clothing used to participate in fitness activities",
        "Fitness Equipment (non apparel) - Products that improve health and wellbeing",
        "Fitness Related Services - Memberships/Fees that improve fitness and wellbeing",
        "Green Living - Items or services to make your home more earth friendly",
        "Health Related Devices - Devices that support health, or wellbeing",
        "Health Related Services - Alternative health and wellness services",
        "Infant Equipment and Furniture, Carriers and Safety Items - Hard goods required for the care and safety of your infant",
        "Individual Insurance premiums - Costs associated with personal insurance premiums",
        "Life Balance - Supports household responsibilities, allowing for participation in fitness and leisure activities",
        "Life Event Preparation",
        "Pay all receipts as submitted - Instead of specifying the coverages above, offer a wide open taxable LSA by selecting this option. This means that we accept all receipts as submitted and will process all expenses without rejections.",
        "Pet Care and Services",
        "Recreational Related Services - Memberships /Fees for recreational activities that improve health and wellbeing",
        "Remote Products - Items that support working remotely",
        "RESP's - Contributions and savings toward your child's post-secondary education",
        "RRSP's - Contributions to personal registered retirement savings plan",
        "Spa Services - Services related to relaxation and wellbeing",
        "Student loan repayment program",
        "TFSA's - Contributions to a tax-free savings account",
        "Transportation (does not include personal automobile expenses) - Costs associated with getting to work",
        "Travel and Vacation",
        "Wellness products (non food) - Products used to support a healthy body"
    ];

    const [accountConfig, setAccountConfig] = useState({
        effectiveDate: "",
        startMonth: "January",
        runoffPeriod: "1 Month",
        carryForward: "None",
        dependentCutoff: "20",
        dependentStudentCutoff: "24",
        companyPlanAdmin: {
            firstName: "", lastName: "", email: "",
        },
    });

    const [fundingOption, setFundingOption] = useState({
        type: "", details: {},
    });

    const [employees, setEmployees] = useState([{
        firstName: "", lastName: "", email: "", birthDate: "", hireDate: "", categoryAssign: "", dependents: [],
    },]);

    useEffect(() => {
        if (accountConfig.effectiveDate === "") {
            const today = new Date();
            const currentYear = today.getFullYear();
            const defaultEffectiveDate = `${currentYear}-01-01`;
            setAccountConfig(prevConfig => ({
                ...prevConfig,
                effectiveDate: defaultEffectiveDate
            }));
        }
    }, [accountConfig.effectiveDate]);

    useEffect(() => {
        fetchEnrollment();
    }, [id]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const modeParam = queryParams.get('mode');
        if (modeParam === 'edit') {
            setMode(modeParam);
        }
    }, [location]);


    const fetchEnrollment = async () => {
        try {
            const response = await fetch(`${apiUrl}/enrollment/getenrollment/${id}`, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Initialize
            const data = await response.json();
            setPolicyName(data.enrollment.policyName);
            setSelectedPlan(data.enrollment.selectedPlan);
            setAdminFee(data.enrollment.adminFee);
            setSubscriber(data.enrollment.policyName)

            setClientFirstName(data.enrollment.clientFirstName);
            setClientLastName(data.enrollment.clientLastName)
            setClientEmail(data.enrollment.clientEmail);

            setEnrollment(data.enrollment);
            const parsedData = JSON.parse(data.enrollment.enrollmentData).enrollmentData;
            const companyInfo = parsedData.companyInfo || {};

            setAdminFee(data.enrollment.adminFee);
            companyInfo.companyNotes = companyInfo.companyNotes || "";
            companyInfo.adminFee = companyInfo.adminFee || 0;
            setCompanyInfo(companyInfo);
            setSelectedPlan(parsedData.selectedPlan);
            setPostalCode(parsedData.companyInfo.companyPostal);

            setAccountConfig(parsedData.accountConfig);
            setSelectedPADOption(parsedData.selectedPADOption)
            setCategories(parsedData.categories);
            setEmployees(parsedData.employees);

        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    let toTitleCase = (str) => {
        return str
            .replace(/([A-Z])/g, ' $1')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    let getSignatureContent = (isDrawing, signature, imageURL, signatureImage) => {
        if (isDrawing) {
            return {
                image: imageURL, width: 150,
            };
        } else {
            return {
                text: signature, font: 'DarceyOliver', fontSize: 100
            };
        }
    }

    const generatePdf = () => {
        const logo = logoBase64;
        const signatureImage = signatureBase64;
        let header = "Enrollment Form" + selectedPlan;
        let planLabel = "";
        if (selectedPlan === "hsa") {
            header = "Health Spending Account Enrollment Form";
            planLabel = " (HSA)";
        } else if (selectedPlan === "lsa") {
            header = "Lifestyle Spending Account Enrollment Form";
            planLabel = " (LSA)";
        } else if (selectedPlan === "fixed") {
            header = "Health and Lifestyle Spending Account Enrollment Form";
            planLabel = " (HSA and LSA)";
        } else if (selectedPlan === "flex") {
            header = "Flexible Spending Account Enrollment Form";
            planLabel = " (Flex)";
        }

        let categoryTable = [[{text: 'Category Name', fillColor: '#223e7f', color: '#fff'}, {
            text: 'Job Description',
            fillColor: '#223e7f',
            color: '#fff'
        }, {text: 'Limit Single', fillColor: '#223e7f', color: '#fff'}, {
            text: 'Limit Couple',
            fillColor: '#223e7f',
            color: '#fff'
        }, {text: 'Limit Family', fillColor: '#223e7f', color: '#fff'}]];

        categories.forEach(category => {
            categoryTable.push([category.categoryName, category.jobDescription, category.limitSingle, category.limitCouple, category.limitFamily]);
        });

        let categoryDetailsTable = [[
            {text: 'Waiting Period', fillColor: '#223e7f', color: '#fff'},
            {text: 'Prorate Existing Employees', fillColor: '#223e7f', color: '#fff'},
            {text: 'Prorate New Hires', fillColor: '#223e7f', color: '#fff'},
            {text: 'Coverage Rate', fillColor: '#223e7f', color: '#fff'}
        ]];

        if (selectedPlan !== "hsa") {
            categoryDetailsTable[0].push({text: 'Allow LSA Dep Claims', fillColor: '#223e7f', color: '#fff'});
        }
        if (selectedPlan === "flex") {
            categoryDetailsTable[0].push({text: 'Flex Plans', fillColor: '#223e7f', color: '#fff'});
        }

        let flexDatesTable = {};
        categories.forEach(category => {
            let categoryRow = [category.waitingPeriod, category.prorateExistingEmployees ? 'Yes' : 'No', category.prorateNewHires ? 'Yes' : 'No', category.coverageRate + '%'];

            if (selectedPlan !== "hsa") {
                categoryRow.push(category.allowLsaDependents ? 'Yes' : 'No');
            }

            if (selectedPlan === "flex") {
                let selectedPlans = [];
                if (category.hasHsa) selectedPlans.push('HSA');
                if (category.hasLsa) selectedPlans.push('LSA');
                if (category.hasRsp) selectedPlans.push('RSP');
                categoryRow.push(selectedPlans.join(', '));

                flexDatesTable = {
                    table: {
                        widths: ['*', '*'],
                        body: [
                            ['Flex Launch Date', 'Flex Deadline'],
                            [category.flexSelectionDate || "", category.flexDeadlineDate || ""]
                        ],
                        margin: [0, 0, 0, 20],
                    },
                    layout: 'lightHorizontalLines'
                };
            }

            categoryDetailsTable.push(categoryRow);
        });

        let configDesc = ["When the plan is to start.  The plan can be back-dated up to one year (will apply to all employees)", "The 12 month cycle that claims are made against.  You can align it to your fiscal year or keep it to a calendar year.", "Number of days from start of new benefit year during which claims can be made against the previous year.", "Credit Carry:  Unused credits from one benefit year can transfer to the next year after the runoff period has ended. Expense Carry:  Expenses (receipts) from one benefit year can be claimed in the next year, after the runoff period has ended. No Carry:  Credits must be used within each benefit year only. No carry forward after the selected run-off period", "Child dependents remain eligible until, and including, this age.", "Child dependents attending full-time post secondary school remain eligible until (and including) this age. "]

        let configTableBody = [];
        let keys = Object.keys(accountConfig);
        let lastKeyIndex = keys.length - 1;
        let i = 0;
        for (let [key, value] of Object.entries(accountConfig)) {
            if (keys.indexOf(key) !== lastKeyIndex) {
                configTableBody.push([{text: toTitleCase(key)}, {text: value}, {text: configDesc[i], fontSize: 10}]);
                i++;
            }
        }

        let employeeContent = employees.map((employee, index) => {
            let employeeLabel = {
                text: 'Employee', fontSize: 16, bold: true, margin: [0, 20, 0, 10]
            };

            let employeeTable1 = {
                margin: [0, 10, 0, 10],
                table: {
                    widths: ['*', '*', '*', '*'],
                    body: [
                        ['First Name', 'Last Name', 'Maiden Name', 'Nick Name'],
                        [employee.firstName || "", employee.lastName || "", employee.maidenName || "", employee.nickName || ""]
                    ]
                },
                layout: 'lightHorizontalLines'
            };

            let employeeTable2 = {
                margin: [0, 0, 0, 20],
                table: {
                    widths: ['30%', '20%', '20%', '30%'],
                    body: [
                        ['Email', 'Date of Birth', 'Hire Date', 'Category'],
                        [
                            {text: employee.email || "", noWrap: false},
                            {text: employee.birthDate || "", noWrap: false},
                            {text: employee.hireDate || "", noWrap: false},
                            {text: employee.categoryAssign || "", noWrap: false}
                        ]
                    ]
                },
                layout: 'lightHorizontalLines'
            };

            let dependentTables = employee.dependents.map(dependent => {
                let body = [['First Name', 'Last Name', 'Date of Birth', 'Relation'], [dependent.firstName || "", dependent.lastName || "", dependent.birthDate || "", dependent.relation]];
                let widths = ['*', '*', '*', '*'];

                if (dependent.studentNotes && dependent.studentNotes.trim() !== "") {
                    body[0].push('Student Notes');
                    body[1].push(dependent.studentNotes);
                    widths.push('*');
                }

                return [{
                    text: 'Dependent', fontSize: 14, bold: true, margin: [0, 0, 0, 10]
                }, {
                    margin: [0, 0, 0, 10], table: {
                        widths: widths,
                        body: body
                    }, layout: 'lightHorizontalLines'
                }];
            });

            let canvasObject = {
                canvas: [{
                    type: 'rect', x: 0, y: 0, w: 515.28, h: 3, color: '#223e7f', margin: [0, 0, 0, 10],
                }]
            };

            return [[employeeLabel, employeeTable1, employeeTable2], ...dependentTables, canvasObject];
        });

        let indemnityContract = {
            // text: ['An Indemnity Contract Providing for the Administration of a', selectedPlan === 'hsa' ? ' Health ' : '', selectedPlan === 'lsa' ? ' Lifestyle ' : '', selectedPlan === 'fixed' ? ' Health and Lifestyle ' : '', selectedPlan === 'flex' ? ' Flexible ' : '', 'Spending Account (Private Health Services Plan) by National HealthClaim'].join(''),
            text: ['An Indemnity Contract and Plan Services Contract Providing for the Administration of a Health Spending Account (Private Health Services Plan) and/or Lifestyle Reimbursement Account by National HealthClaim'].join(''),
            fontSize: 24,
            alignment: 'center',
            margin: [0, 100, 0, 50]
        };

        let indemnityAgreement = {
            text: ['This Administrative Services Indemnity Agreement Made\nas of the ', day, ' day of ', month, ', ', year, '\n\nBetween: ', {
                text: 'National HealthClaim Corporation ("NHC")',
                bold: true
            }, ' and the ', {text: '"Subscriber" ', bold: true}, policyName],
            fontSize: 16,
            alignment: 'center',
            margin: [0, 30, 0, 50],
            pageBreak: 'before'
        };

        let indemnityLegal = [{
            text: [{
                text: 'WHEREAS',
                bold: true
            }, ' NHC and the Subscriber wish to enter into an indemnity contract whereby NHC, for consideration, agrees to indemnify certain persons in respect of certain hospital expenses, medical expenses, medical plans or any combination of such expenses or plans;'],
            fontSize: 12,
            alignment: 'left',
            margin: [0, 0, 0, 10]
        }, {
            text: [{
                text: 'NOW THEREFORE THIS AGREEMENT WITNESSETH',
                bold: true
            }, ' that in consideration of the mutual premises and covenants herein contained, the receipt and sufficiency of which is hereby acknowledged, it is agreed that:'],
            fontSize: 12,
            alignment: 'left',
            margin: [0, 0, 0, 10]
        }, {
            text: 'Definitions', fontSize: 12, bold: true, margin: [0, 20, 0, 10], decoration: 'underline'
        }, {
            text: '1. The following definitions shall be used throughout this agreement:',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            ul: [{
                text: ['a. "Administrative Fee" means the fee to be paid by the Subscriber to NHC concurrently with the Eligible Medical Expenditure, as outlined in Schedule “B”, plus any applicable taxes payable thereon, including Goods and Services Tax and any applicable Provincial taxes;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['b. "Claim Adjudication" means the process whereby NHC adjudicates a claim of a Qualified Person to determine, among other things, whether such claim is a type which is covered by the PHSP and whether such claim has been paid by the Qualified Person;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['c. "Eligible Medical Services" means only those hospital expenses, medical expenses, medical plans or any combination of such expenses or plans which, pursuant to the interpretation by NHC of the Income Tax Act (Canada), including section 248(1) and section 118.2(2) thereof and regulations thereunder, may be included in the PHSP;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['d. "Eligible Medical Expenditure" means expenditures in respect of Eligible Medical Services provided to a Qualified Person, up to maximum amounts as set out in Schedule “A” hereto;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['e. "Eligible Lifestyle Expenditure" means expenditure in respect of Lifestyle Expenses provided to a Qualified Person, up to maximum amounts as set out in Schedule “A” hereto;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['f. "Fee Guide" means the Schedule of Administrative Fees which is attached as Schedule “B” hereto, forming part of this Agreement;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['g. "PHSP" means the private health services plan (known as the Health Spending Account)to be established and administered pursuant to this indemnity contract by NHC on behalf of the Subscriber wherein, upon a Qualified Person making an Eligible Medical Expenditure, the Subscriber shall remit the Premium to NHC, following which, upon a satisfactory Claim Adjudication, NHC shall indemnify the Qualified Person for the cost of the Eligible Medical Expenditure; '],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['h. "Premium" means the sum of the Eligible Medical Expenditure, and/or Eligible Lifestyle Expenditure (depending on the type of plan chosen by the Subscriber), the Administrative Fee, and any applicable taxes payable thereon, including Goods and Services Tax and any applicable Provincial taxes;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['i. "Qualified Person" means such employees of the Subscriber and other persons as are set out in Schedule “A” hereto who may, depending on the plan offered by the Subscriber, include the spouse or common-law partner of the employee (including same-sex common-law partners) or any member of that employee’s household who is a dependent of that employee, as defined for purposes of the Canada Income Tax Act, so long as there is a contract of employment in good standing.'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            },], margin: [20, 0, 0, 10], listType: 'none'
        }, {
            text: 'Covenants of the Subscriber',
            fontSize: 12,
            bold: true,
            margin: [0, 20, 0, 10],
            decoration: 'underline'
        }, {
            text: '2. Upon receipt of a claim from a Qualified Person for reimbursement of a Medical Expenditure and/or Lifestyle Expenditure, the Subscriber shall pay the Premium and applicable taxes to NHC, for NHC to perform the Claim Review and Settlement process.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '3. The Subscriber shall promptly provide funding to settle approved claims with payment options and processing policy outlined in Schedule “C” hereto.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '4. The Subscriber shall keep NHC claim processing systems current as described in Schedule “A” hereto.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: 'Covenants of NHC', fontSize: 12, bold: true, margin: [0, 20, 0, 10], decoration: 'underline'
        }, {
            text: '5. Will commence the Claim Adjudication process upon receipt of the claim details either through the mobile application (ClaimSnap), the online portal, or via post mail, subject to the funding policy in Schedule “C” hereto.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '6. If the Subscriber pays through Pre-authorized Debit (PAD), direct deposit, online payment, or wire transfer, NHC shall follow the steps listed in paragraph 8.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '7. In the event that, through the Claim Adjudication process, NHC determines that the claim is one which is covered by the Health Spending Account, it shall:',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '8. Subject to paragraph 6 and 7 herein, upon receipt of the Premium NHC shall:',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            ul: [{
                text: ['a. maintain the Premium on behalf of the Subscriber until such time as NHC either:'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['i. pays the amount of the Eligible Medical Expenditure and/or Eligible Lifestyle Expenditure to the Qualified Person and subsequently transfers the Administrative Fee to itself, all in accordance with paragraph 6 and 7 herein; or'],
                margin: [20, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['ii. returns the Premium to the Subscriber pursuant to paragraph 10 herein.'],
                margin: [20, 0, 0, 10],
                listType: 'none'
            },], margin: [20, 0, 0, 10], listType: 'none'
        }, {
            text: '9. In the event that, through the Claim Adjudication process, NHC determines that the claim is one which is covered by the Spending Account, it shall:',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            ul: [{
                text: ['a. indemnify that Qualified Person in respect of such claim by issuing a payment through direct deposit or cheque in the amount of such Eligible Medical Expenditure and/or Eligible Lifestyle Expenditure to such Qualified Person;'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['b. issue a Premium statement/invoice to the Subscriber; and\n'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['c. transfer the Administrative Fee to its own account'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            },], margin: [20, 0, 0, 10], listType: 'none'
        }, {
            text: '10. In the event that, through the Claim Adjudication process, NHC determines for any reason that the claim is not one which is covered by the Spending Account, it shall either return the Premium to the Subscriber (if requested by the Subscriber), or hold the balance of Premium in the Subscriber’s account for future use, if funds are already received. NHC will then inform the Qualified Person with an explanation of why the claim is not covered.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '11. NHC shall use commercially reasonable efforts to ensure that the Claim Adjudication process and the indemnification of a Qualified Person (if required) are completed in a timely manner, subject to Schedule “C” hereto.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '12. NHC shall not become entitled to the Administrative Fee unless and until the cheque or payment in the amount of the Eligible Medical Expenditure (if required) and/or Eligible Lifestyle Expenditure (if required) has been issued to the Qualified Person.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '13. NHC reserves the right to change the Administrative Fee on 60 days’ written notice to the Subscriber.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: 'Conditions Precedent', fontSize: 12, bold: true, margin: [0, 20, 0, 10], decoration: 'underline'
        }, {
            text: '14. Conditions precedent to the performance of this indemnity agreement are that:\n',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            ul: [{
                text: ['a. there shall be a contract of employment in good standing between the Subscriber and the Qualified Person or the person through whom such Qualified Person makes a claim pursuant to the Spending Account; and'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['b. the Subscriber shall have an undertaking or a contractual obligation to the Qualified Persons, and each of them, to indemnify such Qualified Persons in respect of Eligible Medical Expenses and/or Eligible Lifestyle Expenses; and that by submitting a Premium and documentation to NHC pursuant to paragraph 2 herein, the Subscriber represents to NHC that such conditions precedent exist.'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            },], margin: [20, 0, 0, 10], listType: 'none'
        }, {
            text: 'Enforcement by Qualified Persons',
            fontSize: 12,
            bold: true,
            margin: [0, 20, 0, 10],
            decoration: 'underline'
        }, {
            text: '15. NHC agrees that Qualified Persons are entitled to enforce NHC’s obligations to indemnify them pursuant to this agreement notwithstanding that such Qualified Persons are not named, nor have they signed, as parties to this agreement and that in the event of such enforcement, NHC shall not raise the issue of privity of contract.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: 'Termination of Agreement',
            fontSize: 12,
            bold: true,
            margin: [0, 20, 0, 10],
            decoration: 'underline'
        }, {
            text: '16. The contract may be terminated',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            ul: [{
                text: ['a. By NHC giving the Subscriber 15 days notice of termination by email.'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['b. By the Subscriber at any time on request, subject to no outstanding claim payment funding.'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            },], margin: [20, 0, 0, 10], listType: 'none'
        }, {
            text: '17. If the contract is terminated by NHC',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            ul: [{
                text: ['a. NHC must refund the excess Premium in the Subscriber’s account that was held for future claim reimbursement; and'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            }, {
                text: ['b. The refund must accompany the notice unless the Premium is subject to adjustment or determination as to amount, in which case the refund must be made as soon as practicable.'],
                margin: [10, 0, 0, 10],
                listType: 'none'
            },], margin: [20, 0, 0, 10], listType: 'none'
        }, {
            text: '18. If the contract is terminated by the Subscriber (company/policy holder), NHC must refund as soon as practicable the excess Premium in the Subscriber’s account that was held for future claim reimbursement.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '19. The 15 day period referred to in subparagraph 16.a of this condition starts to run on the day the email is delivered to the Subscriber’s address.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: 'General', fontSize: 12, bold: true, margin: [0, 20, 0, 10], decoration: 'underline'
        }, {
            text: '20. This Agreement shall be governed by and construed in accordance with the laws of the Province of Alberta and the laws of Canada applicable therein.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '21. The Parties agree that the within agreement is an indemnity contract in respect of hospital expenses, medical expenses, lifestyle expenses, or any combination of such expenses pursuant to the Canada Income Tax Act, including section 248(1) therein and that they will construe and interpret this agreement accordingly.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '22. Headings used in this Agreement are used for convenience only and shall not form the basis for any interpretation of the terms of intent of this Agreement.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '23. If one or more of the provisions of this agreement or any part of them is, or adjudged to be, invalid, illegal or unenforceable in any respect, the validity, legality and enforceability of the remaining provisions hereof shall not in any way be affected or impaired thereby, and such invalid, illegal or unenforceable provision or part shall be deemed to be severable.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '24. The Subscriber acknowledges that it has had the opportunity to obtain its own legal and tax advice with respect to this agreement.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '25. Schedules “A”, “B”, and “C” referred to herein and attached hereto are incorporated by reference to and form part of this agreement.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: '26. This agreement may be executed and delivered in separate counterparts and by electronic means, each of which when so executed and delivered shall constitute the one in the same instrument.',
            fontSize: 12,
            margin: [0, 0, 0, 10]
        }, {
            text: `Signed this ${day} day of ${month}, ${year}.`, fontSize: 12, bold: true, margin: [0, 20, 0, 30]
        }]

        let eligibleExpensesContent = [];
        if (selectedPlan !== "hsa") {
            eligibleExpensesContent.push({
                text: 'Eligible Expenses', fontSize: 16, bold: true, margin: [0, 20, 0, 10], color: '#223e7f'
            });

            eligibleExpensesContent.push({
                ul: companyInfo.eligibleLifestyleExpenses.map(expense => ({
                    text: expense, fontSize: 12, margin: [0, 0, 0, 10]
                })), margin: [20, 0, 0, 10]
            });
        }

        let docDefinition = {
            header: function (currentPage, pageCount) {
                return [{
                    canvas: [{
                        type: 'rect', x: 0, y: 0, w: 595.28, h: 15, color: '#223e7f'
                    }]
                },];
            }, content: [{
                image: logo, width: 200, alignment: 'left'
            }, {
                text: header, font: 'Roboto', fontSize: 22, margin: [0, 20, 0, 30]
            }, {
                text: ['A. Company Information'],
                font: 'Roboto',
                fontSize: 16,
                margin: [0, 0, 0, 6],
                bold: true,
                color: '#223e7f'
            }, {
                canvas: [{
                    type: 'rect', x: 0, y: 0, w: 515.28, h: 5, color: '#223e7f',
                }]
            }, {
                margin: [0, 20, 0, 20], table: {
                    widths: ['30%', '70%'], body: [[{text: 'Company Name', bold: true}, {
                        text: policyName, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Doing Business As', bold: true}, {
                        text: companyInfo.dba, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Address 1', bold: true}, {
                        text: companyInfo.address1, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Address 2', bold: true}, {
                        text: companyInfo.address2, decoration: 'underline', alignment: 'left'
                    }], [{text: 'City', bold: true}, {
                        text: companyInfo.city, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Province', bold: true}, {
                        text: companyInfo.province, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Postal Code', bold: true}, {
                        text: companyInfo.companyPostal, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Advisor Name', bold: true}, {
                        text: companyInfo.brokerName, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Advisor Email', bold: true}, {
                        text: companyInfo.brokerEmail, decoration: 'underline', alignment: 'left'
                    }], [{
                        text: 'Plan Administrator', bold: true
                    }, {
                        text: accountConfig.companyPlanAdmin.firstName + " " + accountConfig.companyPlanAdmin.lastName,
                        decoration: 'underline',
                        alignment: 'left'
                    }], [{text: 'Plan Admin Email', bold: true}, {
                        text: accountConfig.companyPlanAdmin.email, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Funding Option', bold: true}, {
                        text: selectedPADOption, decoration: 'underline', alignment: 'left'
                    }], [{text: 'Enrollment Notes', bold: true}, {
                        text: companyInfo.companyNotes, decoration: 'underline', alignment: 'left'
                    }],]
                }, layout: 'noBorders'
            }, {
                text: ['B. Employee Benefit Categories' + planLabel],
                font: 'Roboto',
                fontSize: 16,
                margin: [0, 0, 0, 6],
                bold: true,
                color: '#223e7f'
            }, {
                canvas: [{
                    type: 'rect', x: 0, y: 0, w: 515.28, h: 5, color: '#223e7f',
                }]
            }, {
                text: 'Choose job classification(s) for the employees of your company. It is required that each employee within a classification be extended the same annual limits. Please make sure the descriptions are accurate.',
                font: 'Roboto',
                margin: [0, 20, 0, 20],
            }, {
                table: {
                    widths: ['*', '*', '*', '*', '*'], body: categoryTable
                }, layout: {
                    hLineWidth: function () {
                        return 1;
                    }, vLineWidth: function () {
                        return 1;
                    }, hLineColor: function () {
                        return '#000';
                    }, vLineColor: function () {
                        return '#000';
                    }
                }, margin: [0, 0, 0, 20],
            },
                {
                    table: {
                        body: categoryDetailsTable
                    }, layout: {}, margin: [0, 0, 0, 20],
                },
                flexDatesTable,
                {
                    table: {
                        widths: ['*', '*', '*'], body: configTableBody
                    }, layout: {
                        hLineWidth: function () {
                            return 0.5;
                        }, vLineWidth: function () {
                            return 0.5;
                        }, hLineColor: function () {
                            return '#000';
                        }, vLineColor: function () {
                            return '#000';
                        },
                    }
                }, ...eligibleExpensesContent, {
                    text: ['C. Employee & Dependent Information'],
                    font: 'Roboto',
                    fontSize: 16,
                    margin: [0, 20, 0, 6],
                    bold: true,
                    color: '#223e7f'
                }, {
                    canvas: [{
                        type: 'rect', x: 0, y: 0, w: 515.28, h: 5, color: '#223e7f',
                    }]
                }, ...employeeContent.flat(),

                {
                    image: logo, width: 200, alignment: 'left', pageBreak: 'before', margin: [0, 20, 0, 20],
                }, indemnityContract, indemnityAgreement, indemnityLegal,

                // Signature
                {
                    table: {
                        widths: ['*', '*'], body: [[{
                            text: 'NATIONAL HEALTHCLAIM CORP.\n\nDavid Howard', fontSize: 12, margin: [0, 0, 0, 10]
                        }, {
                            text: 'the SUBSCRIBER\n\n' + policyName, fontSize: 12, margin: [0, 0, 0, 10]
                        }], [{
                            canvas: [{
                                type: 'line', x1: 0, y1: 0, x2: 170, y2: 0, lineWidth: 1
                            }], margin: [0, 0, 0, 10]
                        }, {
                            canvas: [{
                                type: 'line', x1: 0, y1: 0, x2: 170, y2: 0, lineWidth: 1
                            }], margin: [0, 0, 0, 10]
                        }], [{
                            text: 'Per: (Print full name of Officer)', fontSize: 12, margin: [0, 0, 0, 10]
                        }, {
                            text: 'Per: (Print full name of Subscriber)', fontSize: 12, margin: [0, 0, 0, 10]
                        }], [{
                            image: signatureImage, width: 150, margin: [0, 0, 0, 10]
                        }, getSignatureContent(isDrawing, signature, imageURL)],

                            [{
                                canvas: [{
                                    type: 'line', x1: 0, y1: 0, x2: 170, y2: 0, lineWidth: 1
                                }], margin: [0, 0, 0, 10]
                            }, {
                                canvas: [{
                                    type: 'line', x1: 0, y1: 0, x2: 170, y2: 0, lineWidth: 1
                                }], margin: [0, 0, 0, 10]
                            }], [{
                                text: '(Signature of Officer)', fontSize: 12, margin: [0, 0, 0, 10]
                            }, {
                                text: '(Signature of Subscriber)', fontSize: 12, margin: [0, 0, 0, 10]
                            }], [{
                                text: 'Director', fontSize: 12, margin: [0, 0, 0, 10]
                            }, {
                                text: '', fontSize: 12, margin: [0, 0, 0, 10]
                            }], [{
                                canvas: [{
                                    type: 'line', x1: 0, y1: 0, x2: 170, y2: 0, lineWidth: 1
                                }], margin: [0, 0, 0, 10]
                            }, {
                                text: '', fontSize: 12, margin: [0, 0, 0, 10]
                            }], [{
                                text: 'Title of Officer', fontSize: 12, margin: [0, 0, 0, 10]
                            }, {
                                text: '', fontSize: 12, margin: [0, 0, 0, 10]
                            }],]
                    }, layout: 'noBorders'
                },

                // Admin Fee
                {
                    text: '',
                    pageBreak: 'before'
                },
                {
                    text: 'Schedule “A” \nto Administrative Service Indemnity Agreement \nSubscriber Profile, Plan Design, and Employee Eligibility',
                    fontSize: 16,
                    alignment: 'center',
                    margin: [0, 0, 0, 20]
                },
                {
                    text: 'The complete details of the subscriber profile, Health Spending Account plan design, and the employee eligibility information is recorded in the NHC secure web site. It is the responsibility of the subscriber Plan Administrator to keep this information current.',
                    fontSize: 12,
                    alignment: 'center',
                    margin: [0, 0, 0, 20]
                },
                {
                    text: 'Schedule “B” \nto Administrative Service Indemnity Agreement \nFees and Terms – Spending Account',
                    fontSize: 16,
                    alignment: 'center',
                    margin: [0, 0, 0, 20]
                },
                {
                    text: `1. NHC and the Subscriber agree to an administration fee of ${adminFee}%.`,
                    fontSize: 12,
                    margin: [0, 0, 0, 10]
                },
                {
                    text: '2. Administration Fee – calculated as follows:',
                    fontSize: 12,
                    margin: [0, 0, 0, 10]
                },
                {
                    ul: [
                        'Is a percentage of the employee benefits paid out under the Spending Account.',
                        'GST + applicable taxes are charged on the administration fee and/or the claim amount, dependent on the employee’s province of residence.',
                        'Further details about the administrative fee can be found within the “Product” section of the Plan Administrator online portal.'
                    ],
                    fontSize: 12,
                    margin: [20, 0, 0, 10]
                },
                {
                    text: '3. Accounts must be funded within 120 days of claims being processed. Accounts not funded within 120 days will be placed on hold. Processing of new claims will automatically resume once funds have been received.',
                    fontSize: 12,
                    margin: [0, 0, 0, 10]
                }, ,
                {
                    text: 'Schedule “C” \nto Administrative Service Indemnity Agreement \nAccount Funding Options and Procedures',
                    fontSize: 16,
                    alignment: 'center',
                    margin: [0, 0, 0, 20]
                },
                {
                    text: '1. The HealthCare Spending Account and/or Lifestyle Spending Account can be funded by the Subscriber in multiple ways with associated claim payment turnaround performance.',
                    fontSize: 12,
                    margin: [0, 0, 0, 10]
                },
                {
                    ul: [
                        'The fastest claim payment turnaround time is achieved using Pre-Authorized Debit (PAD). A weekly PAD pull is performed by NHC to top up the funding balance to a predetermined level. During the time between PAD pulls, claims are paid using the current account funds. A separate PAD agreement is required to enable this service.',
                        'Online payment or Direct Deposit options allow the Subscriber to fund their account as desired through their banking services. Funding credit will be applied upon notification by NHC’s bank and will be available for immediate use.',
                        'Funding with Wire Transfer is supported and will be available for immediate use.',
                        'Funding by cheque is supported, however, a 10-day holding period is required to ensure the funds have cleared.'
                    ],
                    fontSize: 12,
                    margin: [20, 0, 0, 10]
                },
                {
                    text: '2. Subscribers who haven\'t paid submitted claims for more than 120 days because of insufficient balance on hand will face a hold on processing any new claims. Processing of new and held claims will automatically resume once funds have been received.',
                    fontSize: 12,
                    margin: [0, 0, 0, 10]
                }
            ], defaultStyle: {
                font: 'Roboto'
            }
        };

        //pdfMake.createPdf(docDefinition).download();
        return new Promise((resolve, reject) => {
            pdfMake.createPdf(docDefinition).getBuffer(buffer => {
                const byteArray = new Uint8Array(buffer);
                const base64String = btoa(byteArray.reduce((data, byte) => data + String.fromCharCode(byte), ''));
                resolve(base64String);
            });
        });
    }

    const handleCompanyInfoChange = (e) => {
        const {id, value, type, checked} = e.target;
        if (type === "checkbox") {
            setCompanyInfo((prev) => {
                if (checked) {
                    return {
                        ...prev,
                        eligibleLifestyleExpenses: Array.isArray(prev.eligibleLifestyleExpenses) ? [...prev.eligibleLifestyleExpenses, id] : [id]
                    };
                } else {
                    return {
                        ...prev, eligibleLifestyleExpenses: prev.eligibleLifestyleExpenses.filter((item) => item !== id)
                    };
                }
            });
        } else {
            setCompanyInfo((prev) => ({
                ...prev, [id]: value,
            }));
        }
    };

    // Validate the postal code
    // Handles both Company & Bank postal code (if PAD)
    const postalCodeCheck = (event, name) => {
        const value = event.target.value;
        const postalCodeRegex = /^[A-Za-z]\d[A-Za-z]\s*\d[A-Za-z]\d$/;
        const isValid = postalCodeRegex.test(value);

        if (name === "companyPostal") {
            setIsValidCompanyPostalCode(isValid);
            setPostalCode(value);
            handleCompanyInfoChange({
                target: {
                    id: "companyPostal", value,
                },
            });
        } else if (name === "bankPostal") {
            setIsValidBankPostalCode(isValid);
            setBankPostalCode(value);
            if (isValid) {
                setFundingOption((prevState) => ({
                    ...prevState, details: {...prevState.details, bankPostalCode: value},
                }));
            }
        }
    };

    const handlePostalCodeBlur = () => {
        setIsPostalCodeTouched(true);
    };

    const handleAddCategory = () => {
        setCategories([...categories, {
            categoryName: "",
            jobDescription: "",
            limitSingle: "",
            limitCouple: "",
            limitFamily: "",
            waitingPeriod: "0",
            prorateExistingEmployees: false,
            prorateNewHires: false,
            coverageRate: 100,
            isCustom: false,
            allowLsaDependents: false,
            hasHsa: false,
            hasLsa: false,
            hasRsp: false,
            flexSelectionDate: "",
            flexDeadlineDate: ""
        },]);
    };

    // If the user selects a "Custom" category, change the dropdown to a text field. 
    // Otherwise, update the state with selected option.
    const handleCategoryChange = (index, e) => {
        const {name, value, type, checked} = e.target;
        const newCategories = [...categories];

        if (type === "checkbox") {
            newCategories[index][name] = checked;
        } else if (name === "categoryName") {
            if (value === "Custom") {
                newCategories[index] = {
                    ...newCategories[index], categoryName: "", isCustom: true, jobDescription: "",
                };
            } else {
                newCategories[index] = {
                    ...newCategories[index],
                    categoryName: value,
                    isCustom: false,
                    jobDescription: predefinedDescriptions[value] || "",
                };
            }
        } else {
            newCategories[index][name] = value;
        }

        setCategories(newCategories);
    };

    const handleCustomCatChange = (index, event) => {
        const newCategories = categories.map((category, idx) => {
            if (idx === index) {
                return {
                    ...category, categoryName: event.target.value,
                };
            }
            return category;
        });

        setCategories(newCategories);
    };

    const handleRemoveCategory = (index) => {
        const newCategories = [...categories];
        newCategories.splice(index, 1);
        setCategories(newCategories);
    };

    const handleAccountConfigChange = (e) => {
        const {name, value} = e.target;
        if (["planAdminFirstName", "planAdminLastName", "planAdminEmail",].includes(name)) {
            const adminKeyMap = {
                planAdminFirstName: "firstName", planAdminLastName: "lastName", planAdminEmail: "email",
            };
            setAccountConfig((prevConfig) => ({
                ...prevConfig, companyPlanAdmin: {
                    ...prevConfig.companyPlanAdmin, [adminKeyMap[name]]: value,
                },
            }));
        } else {
            setAccountConfig((prevConfig) => ({
                ...prevConfig, [name]: value,
            }));
        }
    };

    const [selectedPADOption, setSelectedPADOption] = useState("");

    const handlePADSelect = (event) => {
        const selectedOption = event.target.value;
        setSelectedPADOption(selectedOption);
        setFundingOption({
            type: selectedOption, details: selectedOption === "Pre-Authorized Debit" ? fundingOption.details : {},
        });
    };

    // const handlePADDetailsChange = (event) => {
    //     setFundingOption({
    //         ...fundingOption, details: {
    //             ...fundingOption.details, [event.target.name]: event.target.value,
    //         },
    //     });
    // };

    const handleEmployeeChange = (index, field, value) => {
        setEmployees((employees) => employees.map((employee, i) => {
            if (i === index) {
                return {...employee, [field]: value};
            }
            return employee;
        }));
    };

    const handleDependentChange = (employeeIndex, dependentIndex, field, value) => {
        setEmployees((employees) => employees.map((employee, i) => {
            if (i === employeeIndex) {
                return {
                    ...employee, dependents: employee.dependents.map((dependent, j) => {
                        if (j === dependentIndex) {
                            return {...dependent, [field]: value};
                        }
                        return dependent;
                    }),
                };
            }
            return employee;
        }));
    };

    const addEmployee = () => {
        const newEmployee = {
            firstName: "",
            lastName: "",
            maidenName: "",
            nickName: "",
            email: "",
            birthDate: "",
            hireDate: "",
            categoryAssign: "",
            dependents: [],
        };
        setEmployees([...employees, newEmployee]);
    };

    // 'Add Dependent' button disabled until all employee info is entered.
    // Benefit Category is excluded from this check in case the user doesn't want to assign
    // this employee now (i.e., plans to add more categories later, or hasn't decided yet).
    const canAddDependent = (employee) => {
        return (employee.firstName !== '' && employee.lastName !== '' && employee.email !== '' && employee.birthDate !== '' && employee.hireDate !== '' && employee.categoryAssign !== 'Admin Only' // Do not allow dependents to be added if the employee is assigned to 'Admin Only'
        );
    };

    const addDependent = (employeeIndex) => {
        const newDependents = [...employees];
        const newDependent = {
            firstName: "", lastName: "", birthDate: "", relation: "", studentNotes: ""
        };
        newDependents[employeeIndex].dependents.push(newDependent);
        setEmployees(newDependents);
    };

    const removeEmployee = (employeeIndex) => {
        setEmployees((employees) => employees.filter((_, index) => index !== employeeIndex));
    };

    const removeDependent = (employeeIndex, dependentIndex) => {
        setEmployees((employees) => employees.map((employee, index) => {
            if (index === employeeIndex) {
                const updatedDependents = employee.dependents.filter((_, index) => index !== dependentIndex);
                return {...employee, dependents: updatedDependents};
            }
            return employee;
        }));
    };

    const handleUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Add file handling logic here
            console.log("Processing file", file.name);
            event.target.value = null;
        }
    };

    const verifyRequiredFields = () => {
        return categories.length > 0 && selectedPADOption !== "" && isSigned;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (verifyRequiredFields()) {
            let enrollmentData = JSON.parse(enrollment.enrollmentData);
            enrollmentData.companyInfo = companyInfo;
            enrollmentData.selectedPlan = selectedPlan;
            enrollmentData.categories = categories;
            enrollmentData.accountConfig = accountConfig;
            enrollmentData.employees = employees;
            enrollmentData.selectedPADOption = selectedPADOption;
            enrollment.enrollmentData = enrollmentData;

            try {
                setLoading(true);
                const pdfBytes = await generatePdf();
                const requestOptions = {
                    method: "PUT", headers: {
                        "Content-Type": "application/json",
                    }, body: JSON.stringify({
                        id: id,
                        enrollmentData: JSON.stringify(enrollment),
                        pdfBytes: pdfBytes,
                        recipient: clientEmail,
                        advisorEmail: companyInfo?.brokerEmail,
                        policyName: policyName
                    }),
                };
                const response = await fetch(`${apiUrl}/enrollment/update`, requestOptions);
                if (response.ok) {
                    const data = await response.json();
                    setLoading(false);
                    toastr.success("Enrollment form submitted successfully.");
                    fetchEnrollment();
                } else {
                    setLoading(false);
                    throw new Error(response.statusText);
                }
            } catch (err) {
                toastr.error("Failed to submit enrollment form. Please try again later.");
            }
        } else {
            toastr.error("Please complete all required fields before submitting the form.");
        }

    };

    if (loading) {
        return <Spinner></Spinner>
    }

    // if (error) {
    //     return <div style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         height: "100vh",
    //         flexDirection: "column"
    //     }}>
    //         <h1 style={{textAlign: "center", marginBottom: "2rem"}}>Enrollment not found</h1>
    //         <p style={{textAlign: "center"}}>We're unable to locate the associated enrollment details. Please contact
    //             your advisor for assistance.</p>
    //     </div>
    // }

    if (enrollment.status === "signed") {
        return (<div className="d-flex justify-content-center align-items-center vh-100"
                     style={{backgroundColor: "#223e7f"}}>
            <div className="card p-2" style={{maxWidth: '800px'}}>
                <div className="card-body text-center">

                    <div>
                        <BsSendCheckFill style={{color: '#28a745', width: 80, height: 80, marginBottom: 30}}/>
                    </div>
                    <h1 className="card-title mb-4" style={{fontSize: 32}}>Enrollment Submitted
                        Successfully</h1>
                    <p className="card-text">Thank you for completing and signing your enrollment form. Your
                        form
                        has been successfully submitted and is now under review.</p>
                    <p className="card-text">National HealthClaim will be processing your enrollment shortly. We
                        appreciate your patience during this time. You will be notified once the review process
                        is
                        complete and your enrollment has been processed.</p>
                    <p className="card-text">If you have any questions or need further assistance, please don't
                        hesitate to contact us at <a
                            href="mailto:enrollments@nationalhealthclaim.com">enrollments@nationalhealthclaim.com</a>.
                    </p>
                    <p className="card-text">Thank you for choosing National HealthClaim.</p>
                </div>
            </div>
        </div>);
    }

    const today = new Date();
    const day = today.getDate();
    const month = today.toLocaleString('default', {month: 'long'});
    const year = today.getFullYear();

    const handleSubscriberChange = (event) => {
        setSubscriber(event.target.value);
    };

    const saveSignature = () => {
        setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
    };

    const clearSignature = () => {
        sigCanvas.current.clear();
        setImageURL(null);
    };

    const handleSaveForm = async () => {
        setSaving(true);
        let enrollmentData = JSON.parse(enrollment.enrollmentData);
        enrollmentData.companyInfo = companyInfo;
        enrollmentData.selectedPlan = selectedPlan;
        enrollmentData.categories = categories;
        enrollmentData.accountConfig = accountConfig;
        enrollmentData.employees = employees;
        enrollmentData.selectedPADOption = selectedPADOption;
        enrollment.enrollmentData = enrollmentData;

        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id: enrollment.id,
                    enrollmentData: JSON.stringify(enrollment),
                    clientFirstName: clientFirstName,
                    clientLastName: clientLastName,
                    clientEmail: clientEmail,
                }),
            };
            const response = await fetch(`${apiUrl}/enrollment/save`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                fetchEnrollment();
                setTimeout(() => {
                    setSaving();
                }, 1000);
            } else {
                setSaving(false);
                throw new Error(response.statusText);
            }
        } catch (err) {
            setSaving(false);
        }
    }

    const handleSendForm = async () => {
        setSending(true);

        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id: enrollment.id,
                    email: clientEmail,
                    firstName: clientFirstName,
                    lastName: clientLastName,
                }),
            };
            const response = await fetch(`${apiUrl}/enrollment/send`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                fetchEnrollment();
                setTimeout(() => {
                    setSending(false);
                }, 1000);
            } else {
                setSaving(false);
                throw new Error(response.statusText);
            }
        } catch (err) {
            setSaving(false);
        }

        setShowSendModal(false);
    }

    return (<>
        <Header saving={saving} sending={sending} onSend={() => setShowSendModal(true)} onSave={() => handleSaveForm()}
                mode={mode}/>
        <div className="page__container enroll-container" style={{backgroundColor: "#EAE9EA"}}>
            <div>
                <form onSubmit={handleSubmit}>
                    <div id="form-container" className={"mt-5 form-container"}>
                        <div className="enroll-form-section bg-success text-white"
                             style={{
                                 display: 'flex',
                                 justifyContent: 'space-between',
                                 alignItems: 'center',
                                 flexWrap: 'wrap'
                             }}>
                            <span>Please review the document carefully and proceed to sign when ready.</span>
                            <FaFileSignature size={28}/>
                        </div>

                        <div className="enroll-form-section">
                            <h3>1. Company Information</h3>
                            <div className="mb-4">
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <label htmlFor="policyName" className="form-label">Company Legal Name</label>
                                        <input
                                            id="policyName"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter company legal name"
                                            onChange={(e) => setPolicyName(e.target.value)}
                                            value={policyName}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <label htmlFor="dba" className="form-label">Doing Business As (optional)</label>
                                        <input
                                            id="dba"
                                            type="text"
                                            className="form-control"
                                            onChange={handleCompanyInfoChange}
                                            value={companyInfo?.dba ?? ''}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-4 mb-3">
                                        <label htmlFor="clientFirstName" className="form-label">Client First
                                            Name</label>
                                        <input
                                            id="clientFirstName"
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => setClientFirstName(e.target.value)}
                                            value={clientFirstName ?? ''}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 mb-3">
                                        <label htmlFor="clientLastName" className="form-label">Client Last Name</label>
                                        <input
                                            id="clientLastName"
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => setClientLastName(e.target.value)}
                                            value={clientLastName ?? ''}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 mb-3">
                                        <label htmlFor="clientEmail" className="form-label">Client Email</label>
                                        <input
                                            id="clientEmail"
                                            type="email"
                                            className="form-control"
                                            onChange={(e) => setClientEmail(e.target.value)}
                                            value={clientEmail ?? ''}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="address1" className="form-label">Address Line 1 (Required)</label>
                                    <input
                                        id="address1"
                                        type="text"
                                        className="form-control"
                                        placeholder="1234 Main St"
                                        onChange={handleCompanyInfoChange}
                                        value={companyInfo?.address1 ?? ''}
                                        required
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="address2" className="form-label">Address Line 2 (Optional)</label>
                                    <input
                                        id="address2"
                                        type="text"
                                        className="form-control"
                                        placeholder="Apartment, suite, or floor"
                                        onChange={handleCompanyInfoChange}
                                        value={companyInfo?.address2 ?? ''}
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-4 mb-3">
                                        <label htmlFor="city" className="form-label">City/Town</label>
                                        <input
                                            id="city"
                                            type="text"
                                            className="form-control"
                                            onChange={handleCompanyInfoChange}
                                            value={companyInfo?.city ?? ''}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 mb-3">
                                        <label htmlFor="province" className="form-label">Province</label>
                                        <select
                                            id="province"
                                            className="form-select"
                                            defaultValue={companyInfo?.province ?? 'Choose...'}
                                            onChange={handleCompanyInfoChange}
                                        >
                                            <option>Choose...</option>
                                            <option>Alberta</option>
                                            <option>British Columbia</option>
                                            <option>Manitoba</option>
                                            <option>New Brunswick</option>
                                            <option>Newfoundland & Labrador</option>
                                            <option>Northwest Territories</option>
                                            <option>Nova Scotia</option>
                                            <option>Nunavut</option>
                                            <option>Ontario</option>
                                            <option>Prince Edward Island</option>
                                            <option>Quebec</option>
                                            <option>Saskatchewan</option>
                                            <option>Yukon</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-4 mb-3">
                                        <label htmlFor="companyPostal" className="form-label">Postal Code</label>
                                        <input
                                            id="companyPostal"
                                            type="text"
                                            className={`form-control ${!isValidCompanyPostalCode && isPostalCodeTouched ? 'is-invalid' : ''}`}
                                            name="companyPostal"
                                            value={companyPostal ?? ''}
                                            onChange={(event) => postalCodeCheck(event, "companyPostal")}
                                            onBlur={handlePostalCodeBlur}
                                            placeholder="A0A 0A0"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Postal code format is invalid.
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <label htmlFor="brokerName" className="form-label">Advisor Name</label>
                                        <input
                                            id="brokerName"
                                            type="text"
                                            className="form-control"
                                            onChange={handleCompanyInfoChange}
                                            value={companyInfo?.brokerName ?? ''}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <label htmlFor="brokerEmail" className="form-label">Advisor Email</label>
                                        <input
                                            id="brokerEmail"
                                            type="email"
                                            className="form-control"
                                            onChange={handleCompanyInfoChange}
                                            value={companyInfo?.brokerEmail ?? ''}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="enroll-form-section">
                            <h3 className="mb-3 mb-md-4">2. Policy Benefit Plan</h3>
                            {mode === "edit" && (
                                <div className="my-3">
                                    <div className="mb-3">Select Plan</div>
                                    <div className="row g-3">
                                        <div className="col-12 col-sm-6 col-lg-3">
                                            <Card
                                                className={`h-100 ${selectedPlan === "hsa" ? "border-primary" : ""}`}
                                                onClick={() => setSelectedPlan("hsa")}
                                            >
                                                <Card.Body
                                                    className="d-flex flex-column align-items-center justify-content-center">
                                                    <Card.Title className="text-center">Health Spending
                                                        Account</Card.Title>
                                                    <img src={`${process.env.PUBLIC_URL}/hsa.png`}
                                                         className="img-fluid mt-2" alt="Health Spending Account"/>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-3">
                                            <Card
                                                className={`h-100 ${selectedPlan === "lsa" ? "border-primary" : ""}`}
                                                onClick={() => setSelectedPlan("lsa")}
                                            >
                                                <Card.Body
                                                    className="d-flex flex-column align-items-center justify-content-center">
                                                    <Card.Title className="text-center">Lifestyle Spending
                                                        Account</Card.Title>
                                                    <img src={`${process.env.PUBLIC_URL}/lsa.png`}
                                                         className="img-fluid mt-2" alt="Lifestyle Spending Account"/>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-3">
                                            <Card
                                                className={`h-100 ${selectedPlan === "fixed" ? "border-primary" : ""}`}
                                                onClick={() => setSelectedPlan("fixed")}>
                                                <Card.Body
                                                    className="d-flex flex-column align-items-center justify-content-center">
                                                    <Card.Title className="mb-3">HSA + LSA (Fixed)</Card.Title>
                                                    <div className="d-flex flex-column align-items-center">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/hsa.png`}
                                                            className="img-fluid mb-2"
                                                            alt="Health Spending Account"
                                                            style={{maxWidth: '80%', height: 'auto'}}
                                                        />
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/lsa.png`}
                                                            className="img-fluid"
                                                            alt="Lifestyle Spending Account"
                                                            style={{maxWidth: '80%', height: 'auto'}}
                                                        />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-3">
                                            <Card
                                                className={`h-100 ${selectedPlan === "flex" ? "border-primary" : ""}`}
                                                onClick={() => setSelectedPlan("flex")}
                                            >
                                                <Card.Body
                                                    className="d-flex flex-column align-items-center justify-content-center">
                                                    <Card.Title className="text-center">Flexible Spending
                                                        Account</Card.Title>
                                                    <img src={`${process.env.PUBLIC_URL}/flex.png`}
                                                         className="img-fluid mt-2" alt="Flexible Spending Account"/>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {mode !== "edit" && (
                                <div className="my-3">
                                    <p className="mb-3">Your current plan selection is displayed below. If you wish to
                                        make any changes, please contact your assigned advisor who will be more than
                                        happy to assist you with updates to your selection.</p>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                                            {selectedPlan === "hsa" && (
                                                <Card className="text-center h-100">
                                                    <Card.Body
                                                        className="d-flex flex-column align-items-center justify-content-center">
                                                        <Card.Title>Health Spending Account</Card.Title>
                                                        <img src={`${process.env.PUBLIC_URL}/hsa.png`}
                                                             className="img-fluid mt-3" alt="Health Spending Account"/>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                            {selectedPlan === "lsa" && (
                                                <Card className="text-center h-100">
                                                    <Card.Body
                                                        className="d-flex flex-column align-items-center justify-content-center">
                                                        <Card.Title>Lifestyle Spending Account</Card.Title>
                                                        <img src={`${process.env.PUBLIC_URL}/lsa.png`}
                                                             className="img-fluid mt-3"
                                                             alt="Lifestyle Spending Account"/>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                            {selectedPlan === "fixed" && (
                                                <Card className="text-center h-100">
                                                    <Card.Body
                                                        className="d-flex flex-column align-items-center justify-content-center">
                                                        <Card.Title className="mb-3">HSA + LSA (Fixed)</Card.Title>
                                                        <div className="d-flex flex-column align-items-center">
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/hsa.png`}
                                                                className="img-fluid mb-2"
                                                                alt="Health Spending Account"
                                                                style={{maxWidth: '80%', height: 'auto'}}
                                                            />
                                                            <img
                                                                src={`${process.env.PUBLIC_URL}/lsa.png`}
                                                                className="img-fluid"
                                                                alt="Lifestyle Spending Account"
                                                                style={{maxWidth: '80%', height: 'auto'}}
                                                            />
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                            {selectedPlan === "flex" && (
                                                <Card className="text-center h-100">
                                                    <Card.Body
                                                        className="d-flex flex-column align-items-center justify-content-center">
                                                        <Card.Title>Flexible Spending Account</Card.Title>
                                                        <img src={`${process.env.PUBLIC_URL}/flex.png`}
                                                             className="img-fluid mt-3"
                                                             alt="Flexible Spending Account"/>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="enroll-form-section">
                            <h3>3. Define Benefit Categories</h3>
                            <div className={"my-3"}>
                                Choose job classification(s) for the employees of your company. It is required that
                                each employee within a classification be extended the same annual limits. Please
                                make sure the descriptions are accurate.
                            </div>

                            {(selectedPlan === "hsa" || selectedPlan === "lsa" || selectedPlan === "flex") && categories.map((category, index) => (
                                <div key={index} className="new-bencat">
                                    <Row className="mb-3" key={index}>
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label>Category Name</Form.Label>
                                            <span style={{marginRight: "5px"}}></span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>
                                                    Select a commonly used category
                                                    name, or choose 'Custom' to
                                                    create your own.
                                                </Tooltip>}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                    className="text-primary"
                                                />
                                            </OverlayTrigger>
                                            {category.isCustom ? (<Form.Control
                                                type="text"
                                                name="categoryName"
                                                placeholder="Enter custom category name"
                                                onChange={(e) => handleCustomCatChange(index, e)}
                                                required
                                            />) : (<Form.Select
                                                name="categoryName"
                                                value={category.categoryName}
                                                onChange={(e) => handleCategoryChange(index, e)}
                                            >
                                                <option value="">Select Category...</option>
                                                <option value="Executive">Executive</option>
                                                <option value="Manager">Manager</option>
                                                <option value="All Employees">All Employees</option>
                                                <option value="Full Time">Full Time</option>
                                                <option value="Part Time">Part Time</option>
                                                <option value="Admin Only">Plan Admin Only</option>
                                                <option value="Custom">Custom</option>
                                            </Form.Select>)}
                                        </Form.Group>
                                        <Form.Group as={Col} md={8}>
                                            <Form.Label>Job Description</Form.Label>
                                            <span style={{marginRight: "5px"}}></span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>
                                                    Be detailed, provide a clear description for the job. Sample
                                                    description below.
                                                </Tooltip>}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                    className="text-primary"
                                                />
                                            </OverlayTrigger>
                                            <Form.Control
                                                type="text"
                                                name="jobDescription"
                                                value={category.jobDescription}
                                                onChange={(e) => handleCategoryChange(index, e)}
                                                disabled={category.categoryName === "Admin Only"} // Disable this field when Admin Only is selected
                                                required
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-2">
                                        <Form.Group>
                                            <Form.Label style={{marginRight: 15}}>Check all that apply:</Form.Label>
                                            <Form.Check
                                                type="checkbox"
                                                label="Prorate New Hires?"
                                                onChange={(e) => handleCategoryChange(index, e)}
                                                name="prorateNewHires"
                                                checked={category.prorateNewHires}
                                                inline
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                label="Prorate existing employees?"
                                                onChange={(e) => handleCategoryChange(index, e)}
                                                name="prorateExistingEmployees"
                                                checked={category.prorateExistingEmployees}
                                                inline
                                            />
                                            {selectedPlan !== "hsa" && (
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Allow LSA Dependent Claims?"
                                                    onChange={(e) => handleCategoryChange(index, e)}
                                                    name="allowLsaDependents"
                                                    checked={category.allowLsaDependents}
                                                    inline
                                                />
                                            )}
                                            {selectedPlan === "flex" && (
                                                <>
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Has HSA"
                                                        onChange={(e) => handleCategoryChange(index, e)}
                                                        name="hasHsa"
                                                        checked={category.hasHsa}
                                                        inline
                                                    />
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Has LSA"
                                                        onChange={(e) => handleCategoryChange(index, e)}
                                                        name="hasLsa"
                                                        checked={category.hasLsa}
                                                        inline
                                                    />
                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Has RSP"
                                                        onChange={(e) => handleCategoryChange(index, e)}
                                                        name="hasRsp"
                                                        checked={category.hasRsp}
                                                        inline
                                                    />
                                                </>
                                            )}
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label>Waiting Period</Form.Label>
                                            <Form.Select
                                                name="waitingPeriod"
                                                onChange={(e) => handleCategoryChange(index, e)}
                                                value={category.waitingPeriod}
                                            >
                                                <option value="0">None</option>
                                                <option value="1">1 Month</option>
                                                <option value="2">2 Months</option>
                                                <option value="3">3 Months</option>
                                                <option value="4">4 Months</option>
                                                <option value="5">5 Months</option>
                                                <option value="6">6 Months</option>
                                                <option value="7">7 Months</option>
                                                <option value="8">8 Months</option>
                                                <option value="9">9 Months</option>
                                                <option value="10">10 Months</option>
                                                <option value="11">11 Months</option>
                                                <option value="12">12 Months</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label>
                                                Coverage Rate (%){' '}
                                                <OverlayTrigger
                                                    overlay={<Tooltip>
                                                        This is percentage of claim expenses that will be
                                                        covered by NHC.
                                                    </Tooltip>}
                                                >
                <span className="d-inline-block">
                    <FontAwesomeIcon icon={faInfoCircle} className="text-primary"/>
                </span>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                min="0"
                                                max="100"
                                                defaultValue="100"
                                                name={"coverageRate"}
                                                onChange={(e) => handleCategoryChange(index, e)}
                                            />
                                        </Form.Group>
                                    </Row>

                                    {
                                        selectedPlan === "flex" && (
                                            <div>
                                                <Row className={"mb-3"}>
                                                    <Form.Group as={Col} sm={4}>
                                                        <Form.Label>Flex Launch Date</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            value={category.flexSelectionDate}
                                                            name="flexSelectionDate"
                                                            onChange={(e) => handleCategoryChange(index, e)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm={4}>
                                                        <Form.Label>Flex Selection Deadline</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            value={category.flexDeadlineDate}
                                                            name="flexDeadlineDate"
                                                            onChange={(e) => handleCategoryChange(index, e)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                <div style={{color: "gray", display: "flex", alignItems: "center"}}
                                                     className={"mb-3"}>
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        className="mr-2"
                                                        style={{color: "gray", fontSize: "1.5em", marginRight: "10px"}}
                                                    />
                                                    Flex Selection Launch Date is the date employees are to be emailed their
                                                    first Flex selection link. Please note that it will take a minimum of 24
                                                    hours from the time the enrollment is processed for these emails to
                                                    launch. Employees will receive their initial Welcome emails with login
                                                    instructions only after they have made a selection or the selection
                                                    deadline has passed.
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div style={{color: "gray", display: "flex", alignItems: "center"}}>
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            className="mr-2"
                                            style={{color: "gray", fontSize: "1.5em", marginRight: "10px"}}
                                        />
                                        Please note that proration and wait period are applied at the individual
                                        level. Plan Administrators may be provided instruction in one-on-one
                                        training, or refer to the Plan Admin Handbook for further clarification.
                                    </div>

                                    <div className={"my-3"}>
                                        Please add a value to all 3 fields or it will be assumed one value applies
                                        to all coverage levels.
                                    </div>
                                    <Row>
                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                {selectedPlan.toUpperCase()} Annual Limit {"(Single)"}
                                            </Form.Label>
                                            <div style={{position: "relative"}}>
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                left: "10px",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            $
                                        </span>
                                                <Form.Control
                                                    type="text"
                                                    name="limitSingle"
                                                    value={category.limitSingle}
                                                    onKeyDown={(e) => {
                                                        // Allow only valid input (numbers, decimals, backspace and tab)
                                                        const char = e.key;
                                                        if (char !== "." && char !== "Backspace" && char !== "Tab" && (char < "0" || char > "9")) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => handleCategoryChange(index, e)}
                                                    style={{paddingLeft: "25px"}} // Add left padding to accommodate the $
                                                    disabled={category.categoryName === "Admin Only"}
                                                    required
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                {selectedPlan.toUpperCase()} Annual Limit {"(Couple)"}
                                            </Form.Label>
                                            <div style={{position: "relative"}}>
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                left: "10px",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            $
                                        </span>
                                                <Form.Control
                                                    type="text"
                                                    name="limitCouple"
                                                    value={category.limitCouple}
                                                    onKeyDown={(e) => {
                                                        const char = e.key;
                                                        if (char !== "." && char !== "Backspace" && char !== "Tab" && (char < "0" || char > "9")) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => handleCategoryChange(index, e)}
                                                    style={{paddingLeft: "25px"}}
                                                    disabled={category.categoryName === "Admin Only"}
                                                    required
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                {selectedPlan.toUpperCase()} Annual Limit {"(Family)"}
                                            </Form.Label>
                                            <div style={{position: "relative"}}>
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                left: "10px",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            $
                                        </span>
                                                <Form.Control
                                                    type="text"
                                                    name="limitFamily"
                                                    value={category.limitFamily}
                                                    onKeyDown={(e) => {
                                                        const char = e.key;
                                                        if (char !== "." && char !== "Backspace" && char !== "Tab" && (char < "0" || char > "9")) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => handleCategoryChange(index, e)}
                                                    style={{paddingLeft: "25px"}}
                                                    disabled={category.categoryName === "Admin Only"}
                                                    required
                                                />
                                            </div>
                                        </Form.Group>
                                        <div className="button-wrapper mt-2 mb-3">
                                            {categories.length > 1 && (<Button
                                                variant="danger"
                                                onClick={() => handleRemoveCategory(index)}
                                            >
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>)}
                                        </div>
                                    </Row>
                                </div>))}
                            {(selectedPlan === "fixed") && categories.map((category, index) => (
                                <div key={index} className="new-bencat">
                                    <Row className="mb-3" key={index}>
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label>Category Name</Form.Label>
                                            <span style={{marginRight: "5px"}}></span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>
                                                    Select a commonly used category
                                                    name, or choose 'Custom' to
                                                    create your own.
                                                </Tooltip>}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                    className="text-primary"
                                                />
                                            </OverlayTrigger>
                                            {category.isCustom ? (<Form.Control
                                                type="text"
                                                name="categoryName"
                                                placeholder="Enter custom category name"
                                                onChange={(e) => handleCustomCatChange(index, e)}
                                                required
                                            />) : (<Form.Select
                                                name="categoryName"
                                                value={category.categoryName}
                                                onChange={(e) => handleCategoryChange(index, e)}
                                            >
                                                <option value="">Select Category...</option>
                                                <option value="Executive">Executive</option>
                                                <option value="Manager">Manager</option>
                                                <option value="All Employees">All Employees</option>
                                                <option value="Full Time">Full Time</option>
                                                <option value="Part Time">Part Time</option>
                                                <option value="Admin Only">Plan Admin Only</option>
                                                <option value="Custom">Custom</option>
                                            </Form.Select>)}
                                        </Form.Group>
                                        <Form.Group as={Col} md={8}>
                                            <Form.Label>Job Description</Form.Label>
                                            <span style={{marginRight: "5px"}}></span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>
                                                    Be detailed, provide a clear description for the job. Sample
                                                    description below.
                                                </Tooltip>}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                    className="text-primary"
                                                />
                                            </OverlayTrigger>
                                            <Form.Control
                                                type="text"
                                                name="jobDescription"
                                                value={category.jobDescription}
                                                onChange={(e) => handleCategoryChange(index, e)}
                                                disabled={category.categoryName === "Admin Only"} // Disable this field when Admin Only is selected
                                                required
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-2">
                                        <Form.Group>
                                            <Form.Label style={{marginRight: 15}}>Check all that apply:</Form.Label>
                                            <Form.Check
                                                type="checkbox"
                                                label="Prorate New Hires?"
                                                onChange={(e) => handleCategoryChange(index, e)}
                                                name="prorateNewHires"
                                                checked={category.prorateNewHires}
                                                inline
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                label="Prorate existing employees?"
                                                onChange={(e) => handleCategoryChange(index, e)}
                                                name="prorateExistingEmployees"
                                                checked={category.prorateExistingEmployees}
                                                inline
                                            />
                                            {selectedPlan !== "hsa" && (
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Allow LSA Dependent Claims?"
                                                    onChange={(e) => handleCategoryChange(index, e)}
                                                    name="allowLsaDependents"
                                                    checked={category.allowLsaDependents}
                                                    inline
                                                />
                                            )}
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label>Waiting Period</Form.Label>
                                            <Form.Select
                                                name="waitingPeriod"
                                                onChange={(e) => handleCategoryChange(index, e)}
                                                value={category.waitingPeriod}
                                            >
                                                <option value="0">None</option>
                                                <option value="1">1 Month</option>
                                                <option value="2">2 Months</option>
                                                <option value="3">3 Months</option>
                                                <option value="4">4 Months</option>
                                                <option value="5">5 Months</option>
                                                <option value="6">6 Months</option>
                                                <option value="7">7 Months</option>
                                                <option value="8">8 Months</option>
                                                <option value="9">9 Months</option>
                                                <option value="10">10 Months</option>
                                                <option value="11">11 Months</option>
                                                <option value="12">12 Months</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} md={4}>
                                            <Form.Label>
                                                Coverage Rate (%){' '}
                                                <OverlayTrigger
                                                    overlay={<Tooltip>
                                                        This is percentage of claim expenses that will be
                                                        covered by NHC.
                                                    </Tooltip>}
                                                >
            <span className="d-inline-block">
                    <FontAwesomeIcon icon={faInfoCircle} className="text-primary"/>
                </span>
                                                </OverlayTrigger>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                min="0"
                                                max="100"
                                                defaultValue="100"
                                                name={"coverageRate"}
                                                onChange={(e) => handleCategoryChange(index, e)}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <div style={{color: "gray", display: "flex", alignItems: "center"}}>
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            className="mr-2"
                                            style={{color: "gray", fontSize: "1.5em", marginRight: "10px"}}
                                        />
                                        Please note that proration and wait period are applied at the individual
                                        level. Plan Administrators may be provided instruction in one-on-one
                                        training, or refer to the Plan Admin Handbook for further clarification.
                                    </div>

                                    <div className={"my-3"}>
                                        Please add a value to all 3 fields or it will be assumed one value applies
                                        to all coverage levels.
                                    </div>
                                    <Row>
                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                HSA Annual Limit {"(Single)"}
                                            </Form.Label>
                                            <div style={{position: "relative"}}>
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                left: "10px",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            $
                                        </span>
                                                <Form.Control
                                                    type="text"
                                                    name="limitSingleHSA"
                                                    value={category.limitSingleHSA}
                                                    onKeyDown={(e) => {
                                                        // Allow only valid input (numbers, decimals, backspace and tab)
                                                        const char = e.key;
                                                        if (char !== "." && char !== "Backspace" && char !== "Tab" && (char < "0" || char > "9")) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => handleCategoryChange(index, e)}
                                                    style={{paddingLeft: "25px"}} // Add left padding to accommodate the $
                                                    disabled={category.categoryName === "Admin Only"}
                                                    required
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                HSA Annual Limit {"(Couple)"}
                                            </Form.Label>
                                            <div style={{position: "relative"}}>
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                left: "10px",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            $
                                        </span>
                                                <Form.Control
                                                    type="text"
                                                    name="limitCoupleHSA"
                                                    value={category.limitCoupleHSA}
                                                    onKeyDown={(e) => {
                                                        const char = e.key;
                                                        if (char !== "." && char !== "Backspace" && char !== "Tab" && (char < "0" || char > "9")) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => handleCategoryChange(index, e)}
                                                    style={{paddingLeft: "25px"}}
                                                    disabled={category.categoryName === "Admin Only"}
                                                    required
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                HSA Annual Limit {"(Family)"}
                                            </Form.Label>
                                            <div style={{position: "relative"}}>
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                left: "10px",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            $
                                        </span>
                                                <Form.Control
                                                    type="text"
                                                    name="limitFamilyHSA"
                                                    value={category.limitFamilyHSA}
                                                    onKeyDown={(e) => {
                                                        const char = e.key;
                                                        if (char !== "." && char !== "Backspace" && char !== "Tab" && (char < "0" || char > "9")) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => handleCategoryChange(index, e)}
                                                    style={{paddingLeft: "25px"}}
                                                    disabled={category.categoryName === "Admin Only"}
                                                    required
                                                />
                                            </div>
                                        </Form.Group>
                                        <div className="button-wrapper mt-2 mb-3">
                                            {categories.length > 1 && (<Button
                                                variant="danger"
                                                onClick={() => handleRemoveCategory(index)}
                                            >
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>)}
                                        </div>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                LSA Annual Limit {"(Single)"}
                                            </Form.Label>
                                            <div style={{position: "relative"}}>
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                left: "10px",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            $
                                        </span>
                                                <Form.Control
                                                    type="text"
                                                    name="limitSingleLSA"
                                                    value={category.limitSingleLSA}
                                                    onKeyDown={(e) => {
                                                        // Allow only valid input (numbers, decimals, backspace and tab)
                                                        const char = e.key;
                                                        if (char !== "." && char !== "Backspace" && char !== "Tab" && (char < "0" || char > "9")) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => handleCategoryChange(index, e)}
                                                    style={{paddingLeft: "25px"}} // Add left padding to accommodate the $
                                                    disabled={category.categoryName === "Admin Only"}
                                                    required
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                LSA Annual Limit {"(Couple)"}
                                            </Form.Label>
                                            <div style={{position: "relative"}}>
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                left: "10px",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            $
                                        </span>
                                                <Form.Control
                                                    type="text"
                                                    name="limitCoupleLSA"
                                                    value={category.limitCoupleLSA}
                                                    onKeyDown={(e) => {
                                                        const char = e.key;
                                                        if (char !== "." && char !== "Backspace" && char !== "Tab" && (char < "0" || char > "9")) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => handleCategoryChange(index, e)}
                                                    style={{paddingLeft: "25px"}}
                                                    disabled={category.categoryName === "Admin Only"}
                                                    required
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                LSA Annual Limit {"(Family)"}
                                            </Form.Label>
                                            <div style={{position: "relative"}}>
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                transform: "translateY(-50%)",
                                                left: "10px",
                                                pointerEvents: "none",
                                            }}
                                        >
                                            $
                                        </span>
                                                <Form.Control
                                                    type="text"
                                                    name="limitFamilyLSA"
                                                    value={category.limitFamilyLSA}
                                                    onKeyDown={(e) => {
                                                        const char = e.key;
                                                        if (char !== "." && char !== "Backspace" && char !== "Tab" && (char < "0" || char > "9")) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={(e) => handleCategoryChange(index, e)}
                                                    style={{paddingLeft: "25px"}}
                                                    disabled={category.categoryName === "Admin Only"}
                                                    required
                                                />
                                            </div>
                                        </Form.Group>
                                        <div className="button-wrapper mt-2 mb-3">
                                            {categories.length > 1 && (<Button
                                                variant="danger"
                                                onClick={() => handleRemoveCategory(index)}
                                            >
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>)}
                                        </div>
                                    </Row>
                                </div>

                            ))}
                            {selectedPlan !== null && <div className="button-wrapper">
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={handleAddCategory}
                                    className="ml-auto mt-3"
                                >
                                    Add {categories.length > 0 && <span>another</span>} category
                                </Button>
                            </div>}

                            {selectedPlan !== "hsa" && <div className={"new-bencat mt-3"}>
                                <div className={"mb-2"}>Eligible Lifestyle Expenses</div>
                                {lifestyleOptions.map((option, index) => (
                                    <Form.Check
                                        key={index}
                                        type="checkbox"
                                        label={option}
                                        id={option}
                                        value={option}
                                        checked={companyInfo.eligibleLifestyleExpenses?.includes(option) || false}
                                        onChange={handleCompanyInfoChange}
                                    />
                                ))}
                            </div>}
                            <div className={"mt-3"}>
                                <Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>
                                            If necessary, please provide any clarification about plan details here.
                                            Should you have any questions or require further customization please
                                            reach out to enrollments@nationalhealthclaim.com.
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            id="companyNotes"
                                            placeholder="Enter notes (optional)"
                                            onChange={handleCompanyInfoChange}
                                            value={companyInfo?.companyNotes ?? ''}
                                        />
                                    </Form.Group>
                                </Row>
                            </div>
                        </div>

                        <div className="enroll-form-section">
                            <h3>4. Account Configuration</h3>
                            <br/>
                            <Row className="mb-3">
                                <Form.Group as={Col} sm={2}>
                                    <Form.Label>Plan Effective Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={accountConfig.effectiveDate}
                                        name="effectiveDate"
                                        onChange={handleAccountConfigChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group
                                    as={Col}
                                    sm={2}
                                    style={{marginRight: "50px"}}
                                >
                                    <Form.Label>
                                        Benefit Year Starts {`(Month)`}
                                    </Form.Label>
                                    <Form.Select
                                        name="startMonth"
                                        onChange={handleAccountConfigChange}
                                        value={accountConfig.startMonth}
                                    >
                                        <option value="January">January</option>
                                        <option value="February">February</option>
                                        <option value="March">March</option>
                                        <option value="April">April</option>
                                        <option value="May">May</option>
                                        <option value="June">June</option>
                                        <option value="July">July</option>
                                        <option value="August">August</option>
                                        <option value="September">September</option>
                                        <option value="October">October</option>
                                        <option value="November">November</option>
                                        <option value="December">December</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group as={Col} sm={2}>
                                    <Form.Label>Runoff Period</Form.Label>
                                    <span style={{marginRight: "5px"}}></span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            The deadline for employees to submit claims for expenses from
                                            the
                                            previous
                                            benefit year. The CRA requires a minimum 30 days.
                                        </Tooltip>}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            className="text-primary"
                                        />
                                    </OverlayTrigger>
                                    {["radio"].map((type) => (<div key={`runoff-${type}`} className="mb-3">
                                        <Form.Check
                                            type="radio"
                                            name="runoffPeriod"
                                            value="1 Month"
                                            checked={accountConfig.runoffPeriod === "1 Month"}
                                            onChange={handleAccountConfigChange}
                                            label="1 Month"
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="runoffPeriod"
                                            value="2 Months"
                                            checked={accountConfig.runoffPeriod === "2 Months"}
                                            onChange={handleAccountConfigChange}
                                            label="2 Months"
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="runoffPeriod"
                                            value="3 Months"
                                            checked={accountConfig.runoffPeriod === "3 Months"}
                                            onChange={handleAccountConfigChange}
                                            label="3 Months"
                                        />
                                    </div>))}
                                </Form.Group>
                                <Form.Group as={Col} sm={2}>
                                    <Form.Label>Carry Forward Options</Form.Label>
                                    <span style={{marginRight: "5px"}}></span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Credit: Unused credits from the previous
                                            year carry into the next benefit year.
                                            Expense: Expenses {"(receipts)"} from
                                            the previous year can be claimed against
                                            the next year's benefit amount.
                                        </Tooltip>}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            className="text-primary"
                                        />
                                    </OverlayTrigger>
                                    <div className="mb-3">
                                        <Form.Check
                                            type="radio"
                                            name="carryForward"
                                            label="Credit"
                                            value="Credit"
                                            checked={accountConfig.carryForward === "Credit"}
                                            onChange={handleAccountConfigChange}
                                        />
                                        <Form.Check
                                            type="radio"
                                            name="carryForward"
                                            label="Expense"
                                            value="Expense"
                                            checked={accountConfig.carryForward === "Expense"}
                                            onChange={handleAccountConfigChange}
                                        />
                                        {/*<Form.Check*/}
                                        {/*    type="radio"*/}
                                        {/*    name="carryForward"*/}
                                        {/*    label="Credit & Expense"*/}
                                        {/*    value="Both"*/}
                                        {/*    checked={*/}
                                        {/*        accountConfig.carryForward === "Both"*/}
                                        {/*    }*/}
                                        {/*    onChange={handleAccountConfigChange}*/}
                                        {/*/>*/}
                                        <Form.Check
                                            type="radio"
                                            name="carryForward"
                                            label="No Carry Forward"
                                            value="None"
                                            checked={accountConfig.carryForward === "None"}
                                            onChange={handleAccountConfigChange}
                                        />
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>
                                        Child Dependent Cutoff Age {"(Non-student)"}
                                    </Form.Label>
                                    <span style={{marginRight: "5px"}}></span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            The age at which dependent children are no longer eligible for
                                            the
                                            HSA. Usually age 20.
                                        </Tooltip>}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            className="text-primary"
                                        />
                                    </OverlayTrigger>
                                    <Form.Control
                                        name="dependentCutoff"
                                        type="number"
                                        value={accountConfig.dependentCutoff}
                                        min="20"
                                        max="27"
                                        style={{width: "70px"}}
                                        onChange={handleAccountConfigChange}
                                        required
                                    />
                                    <br/>
                                    <Form.Label>
                                        Child Dependent Cutoff Age{" "}
                                        {"(Secondary student)"}
                                    </Form.Label>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            The age at which dependent children enrolled in post-secondary
                                            education are no longer
                                            eligible for the HSA. Usually age 24.
                                        </Tooltip>}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            className="text-primary"
                                        />
                                    </OverlayTrigger>
                                    <Form.Control
                                        name="dependentStudentCutoff"
                                        type="number"
                                        value={accountConfig.dependentStudentCutoff}
                                        min="20"
                                        max="27"
                                        style={{width: "70px"}}
                                        onChange={handleAccountConfigChange}
                                        required
                                    />
                                </Form.Group>
                            </Row>
                            <h5>Company Plan Administrator:</h5>
                            <br/>
                            <Row style={{marginBottom: "30px"}}>
                                <Form.Group as={Col} sm={2}>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="planAdminFirstName"
                                        value={accountConfig.companyPlanAdmin.firstName}
                                        onChange={handleAccountConfigChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group as={Col} sm={2}>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="planAdminLastName"
                                        value={accountConfig.companyPlanAdmin.lastName}
                                        onChange={handleAccountConfigChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group as={Col} sm={2}>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="planAdminEmail"
                                        value={accountConfig.companyPlanAdmin.email}
                                        onChange={handleAccountConfigChange}
                                        required
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <h5>Account Funding:</h5>
                                <br/>
                                <div>
                                    By signing this enrollment form, the company agrees to provide a Spending Account
                                    for its employees and will pay for all account funding and administration fees as
                                    required.
                                </div>
                                <Form.Group
                                    as={Col}
                                    sm={5}
                                    style={{marginTop: "20px"}}
                                >
                                    <Form.Label>Select Funding Option</Form.Label>
                                    <Form.Select
                                        value={selectedPADOption}
                                        onChange={handlePADSelect}
                                    >
                                        <option value="">Choose...</option>
                                        <option value="Pre-Authorized Debit">
                                            Pre-Authorized Debit (Recommended)
                                        </option>
                                        <option value="Online Banking">
                                            Online Banking (Manual)
                                        </option>
                                        <option value="Company Cheque">
                                            Company Cheque
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                                {
                                    selectedPADOption === "Pre-Authorized Debit" && (
                                        <p className={"mt-3"}>
                                            Once your enrollment is complete, National HealthClaim will send you an email
                                            with a link to the Pre-Authorized Debit (PAD) sign-up form. Please follow the
                                            link to enter your banking information and finalize your PAD setup.
                                        </p>
                                    )
                                }
                                {
                                    selectedPADOption === "Company Cheque" && (
                                        <p className={"mt-3"}>
                                            Please send a "Pre-Funding Cheque" to <b>National HealthClaim Corp.</b> at the
                                            following address: <b>335 58th Ave S.E., Calgary, Alberta, T2H 0P3</b>.
                                        </p>
                                    )
                                }

                            </Row>
                        </div>
                        <div className="enroll-form-section">
                            <Row>
                                <Col sm={8}>
                                    <h3>5. Employee & Dependent Information</h3>
                                    <br/>
                                </Col>

                            </Row>

                            {employees.map((employee, employeeIndex) => (<div
                                key={employeeIndex}
                                id={`new-employee-${employeeIndex}`}
                                className="new-employee"
                            >
                                <h5
                                    style={{
                                        marginTop: "25px", marginBottom: "15px",
                                    }}
                                    id={`employee-header-${employeeIndex}`}
                                >
                                    Employee
                                    {employee.firstName && employee.lastName ? `: ${employee.firstName} ${employee.lastName}` : ""}
                                </h5>
                                <Row>
                                    <Form.Group as={Col} sm={3}>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={employees[employeeIndex].firstName || ""}
                                            onChange={(e) => handleEmployeeChange(employeeIndex, "firstName", e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={3}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={employees[employeeIndex].lastName || ""}
                                            onChange={(e) => handleEmployeeChange(employeeIndex, "lastName", e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={3}>
                                        <Form.Label>Maiden Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={employees[employeeIndex].maidenName || ""}
                                            onChange={(e) => handleEmployeeChange(employeeIndex, "maidenName", e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={3}>
                                        <Form.Label>Nick Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={employees[employeeIndex].nickName || ""}
                                            onChange={(e) => handleEmployeeChange(employeeIndex, "nickName", e.target.value)}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} sm={3} className={"mt-2"}>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" value={employees[employeeIndex].email || ""}
                                                      onChange={(e) => handleEmployeeChange(employeeIndex, "email", e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group as={Col} sm={3} className={"mt-2"}>
                                        <Form.Label>Date of Birth</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={employees[employeeIndex].birthDate || ""}
                                            onChange={(e) => handleEmployeeChange(employeeIndex, "birthDate", e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={3} className={"mt-2"}>
                                        <Form.Label>Hire Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={employees[employeeIndex].hireDate || ""}
                                            onChange={(e) => handleEmployeeChange(employeeIndex, "hireDate", e.target.value)}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} sm={3} className={"mt-2"}>
                                        <Form.Label>Benefit Category</Form.Label>
                                        {categories.every((category) => !category.categoryName) && (<>
                                            <span style={{marginRight: "5px"}}></span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>
                                                    Create at least one<br/>Benefit Category first
                                                </Tooltip>}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                    className="text-primary"
                                                />
                                            </OverlayTrigger>
                                        </>)}
                                        <Form.Select
                                            value={employees[employeeIndex].categoryAssign || ""}
                                            onChange={(e) => handleEmployeeChange(employeeIndex, "categoryAssign", e.target.value)}
                                            disabled={categories.length === 0 || (categories.length === 1 && categories[0].categoryName === "")}
                                        >
                                            <option value="">Choose...</option>
                                            {categories.map((category, index) => (<option
                                                key={index}
                                                value={category.categoryName}
                                            >
                                                {category.categoryName}
                                            </option>))}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>

                                {employee.dependents.length > 0 && (<h5 style={{marginTop: "25px"}}>
                                    {employee.firstName.endsWith("s") ? `${employee.firstName}'` : `${employee.firstName}'s`}{" "}
                                    dependents:
                                </h5>)}

                                {employee.dependents.map((dependent, dependentIndex) => (<div
                                    key={dependentIndex}
                                    style={{marginTop: "15px"}}
                                >
                                    <Row>
                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                First Name
                                            </Form.Label>
                                            <Form.Control
                                                value={dependent.firstName}
                                                onChange={(e) => handleDependentChange(employeeIndex, dependentIndex, "firstName", e.target.value)}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                Last Name
                                            </Form.Label>
                                            <Form.Control
                                                value={dependent.lastName}
                                                onChange={(e) => handleDependentChange(employeeIndex, dependentIndex, "lastName", e.target.value)}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                Date of Birth
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                value={dependent.birthDate}
                                                onChange={(e) => handleDependentChange(employeeIndex, dependentIndex, "birthDate", e.target.value)}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} sm={2}>
                                            <Form.Label>
                                                Relationship
                                            </Form.Label>
                                            <span
                                                style={{
                                                    marginRight: "5px",
                                                }}
                                            ></span>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip>
                                                    "Unconditional" means an adult dependent
                                                    whose
                                                    eligibility is due to extenuating
                                                    circumstances, such as prolonged illness or
                                                    disability.
                                                </Tooltip>}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                    className="text-primary"
                                                />
                                            </OverlayTrigger>
                                            <Form.Select
                                                value={dependent.relation}
                                                onChange={(e) => handleDependentChange(employeeIndex, dependentIndex, "relation", e.target.value)}
                                            >
                                                <option value="">
                                                    Choose...
                                                </option>
                                                <option value="Spouse">
                                                    Spouse
                                                </option>
                                                <option value="Child (Non-student)">
                                                    Child (Non-student)
                                                </option>
                                                <option value="Child (Post-secondary student)">
                                                    Child (Post-secondary
                                                    student)
                                                </option>
                                                <option value="Unconditional">
                                                    Unconditional
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                        {
                                            dependent.relation === "Child (Post-secondary student)" &&
                                            <FormGroup as={Col} sm={2}>
                                                <Form.Label>
                                                    Student Notes
                                                </Form.Label>
                                                <span
                                                    style={{
                                                        marginRight: "5px",
                                                    }}
                                                ></span>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip>
                                                        If possible please provide school name and term length (0-2
                                                        years, 2+ years, etc)
                                                    </Tooltip>}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faInfoCircle}
                                                        className="text-primary"
                                                    />
                                                </OverlayTrigger>
                                                <Form.Control
                                                    type="text"
                                                    value={dependent.studentNotes || ""}
                                                    onChange={(e) => handleDependentChange(employeeIndex, dependentIndex, "studentNotes", e.target.value)}
                                                />
                                            </FormGroup>
                                        }

                                        <Col sm={2}>
                                            <Button
                                                variant="danger"
                                                onClick={() => removeDependent(employeeIndex, dependentIndex)}
                                                style={{
                                                    marginTop: "32px",
                                                }}
                                            >

                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>))}
                                <Row>
                                    <Col>
                                        <div className="button-wrapper mt-3">
                                            <Button
                                                variant="secondary"
                                                onClick={() => addDependent(employeeIndex)}
                                                disabled={!canAddDependent(employee)}
                                            >
                                                Add Dependent
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>))}
                            <div className="button-wrapper mt-3">
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={addEmployee}
                                    className="ml-auto"
                                >
                                    Add another employee
                                </Button>
                            </div>
                            <div className={"mt-3"}>
                                <div className={"my-2"}>Employee files can also be uploaded below. Accepted formats
                                    include CSV, Excel,
                                    Numbers, MS Docs, and Google Docs, with a maximum file size of 10MB.
                                </div>
                                <FileUploader id={id}></FileUploader>
                            </div>

                        </div>
                        <div className="enroll-form-section pb-5">
                            <Row>
                                <Col sm={12}>
                                    <h3>6. Indemnity Contract</h3>
                                    <br/>
                                </Col>
                            </Row>
                            <Row>
                                <div style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    width: '60%',
                                    fontSize: 24,
                                    textAlign: 'center'
                                }}>
                                    An Indemnity Contract and Plan Services Contract Providing for the Administration of
                                    a Health Spending Account (Private Health Services Plan) and/or Lifestyle
                                    Reimbursement Account by National HealthClaim
                                    {/*An Indemnity Contract Providing for the Administration of a*/}
                                    {/*{selectedPlan === 'hsa' && " Health "}*/}
                                    {/*{selectedPlan === 'lsa' && " Lifestyle "}*/}
                                    {/*{(selectedPlan === 'fixed' || selectedPlan === 'flex') && " Health and Lifestyle "}*/}
                                    {/*Spending Account (Private Health Services Plan) by National HealthClaim*/}
                                </div>
                                <div style={{textAlign: 'center', margin: 20}}>
                                    This Administrative Services Indemnity Agreement Made as of the {day} day
                                    of {month}, {year}
                                </div>
                                <div style={{textAlign: 'center', margin: 20}}>
                                    Between: <b>National HealthClaim Corporation ("NHC")</b><br/>and
                                    the <b>"Subscriber"</b>
                                    <div className="d-flex form-inline justify-content-center">
                                        <input
                                            type="text"
                                            onChange={(e) => setPolicyName(e.target.value)}
                                            value={policyName}
                                            className="form-control ml-2 mt-3"
                                            style={{width: 250}}
                                            placeholder={"Enter subscriber name"}
                                            disabled
                                        />
                                        {/*<input*/}
                                        {/*    type="text"*/}
                                        {/*    value={subscriber}*/}
                                        {/*    onChange={handleSubscriberChange}*/}
                                        {/*    className="form-control ml-2 mt-3"*/}
                                        {/*    style={{width: 250}}*/}
                                        {/*    placeholder={"Enter subscriber name"}*/}
                                        {/*    disabled*/}
                                        {/*/>*/}
                                    </div>
                                </div>
                                <IndemnityContract/>
                                {
                                    mode !== 'edit' && (
                                        <div>
                                            <div style={{
                                                display: 'flex', justifyContent: 'space-between', marginTop: 50
                                            }}>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    width: '50%',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                    <div style={{fontSize: 24, fontWeight: 500, marginBottom: 50}}>
                                                        National HealthClaim Corporation
                                                    </div>
                                                    <div style={{fontSize: 20, fontWeight: 500, marginBottom: 20}}>
                                                        David Howard
                                                    </div>
                                                    <div style={{borderTop: '3px solid black', width: '80%'}}></div>
                                                    <div style={{marginBottom: 50}}>Per: (Print full name of Officer)
                                                    </div>

                                                    <div style={{maxHeight: 100, height: 100}}>
                                                        <img src="/officersignature.png" style={{height: 100}}
                                                             alt="Logo"/></div>
                                                    <div style={{borderTop: '3px solid black', width: '80%'}}></div>
                                                    <div>(Signature of Officer)</div>
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    width: '50%',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                    <div style={{fontSize: 24, fontWeight: 500, marginBottom: 50}}>
                                                        the SUBSCRIBER
                                                    </div>
                                                    <div style={{fontSize: 20, fontWeight: 500, marginBottom: 20}}>
                                                        {policyName}
                                                    </div>
                                                    <div style={{borderTop: '3px solid black', width: '80%'}}></div>
                                                    <div style={{marginBottom: 50}}>Per: (Print full name of Subscriber)
                                                    </div>

                                                    {isSigned && !isDrawing ?
                                                        <div style={{maxHeight: 100, height: 100, cursor: 'pointer'}}
                                                             type={"button"}
                                                             onClick={() => setShowSignatureModal(true)}>
                                                            <div className="signature"
                                                                 style={{cursor: 'pointer'}}>{signature}</div>
                                                        </div> : null}
                                                    {isSigned && isDrawing ? <div
                                                        style={{maxHeight: 100, height: 100, cursor: 'pointer'}}
                                                        type={"button"}
                                                        onClick={() => setShowSignatureModal(true)}>
                                                        {imageURL ? <img src={imageURL} alt="Signature"/> : null}
                                                    </div> : null}
                                                    {!isSigned && <div style={{maxHeight: 100, height: 100}}>
                                                        <button className="btn btn-success" type={"button"}
                                                                onClick={() => setShowSignatureModal(true)}
                                                                style={{fontSize: 20, padding: "10px 15px"}}>Sign
                                                            Here <PiSignatureBold
                                                                size={25}
                                                                style={{marginLeft: 8}}/>
                                                        </button>
                                                    </div>}
                                                    <div style={{borderTop: '3px solid black', width: '80%'}}></div>
                                                    <div>(Signature of Subscriber)</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </Row>
                        </div>
                        <div className="enroll-form-section mb-5 pb-5">
                            <div style={{
                                marginTop: 20,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '60%',
                                fontSize: 24,
                                textAlign: 'center'
                            }}>
                                Schedule “A” <br/>to Administrative Service Indemnity Agreement <br/> <br/><u><b>Subscriber
                                Profile, Plan Design, and Employee Eligibility</b></u>
                            </div>
                            <div style={{textAlign: 'center', margin: 20}}>
                                The complete details of the Subscriber profile, Spending Account (includes both Health
                                Spending Account and Lifestyle Spending account options) plan design, and Qualified
                                Person (employee) eligibility information is recorded on the NHC secure
                                website.<br/><br/>
                                It is the responsibility of the Subscriber Plan Administrator to keep their account
                                current.
                            </div>

                            <div style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '60%',
                                fontSize: 24,
                                textAlign: 'center',
                                marginTop: 50
                            }}>
                                Schedule “B” <br/>to Administrative Service Indemnity Agreement <br/><br/>
                                <u><b>Fees and Terms –
                                    HealthCare Spending Account</b></u>
                            </div>
                            <div className={"my-3 px-5 mt-4"}>
                                <p>1. NHC and the Subscriber agree to an administration fee of <b>{adminFee}%</b></p>
                                <p>2. Administration Fee – calculated as follows:</p>
                                <ul>
                                    <li>Is a percentage of the employee benefits paid out under the Spending Account.
                                    </li>
                                    <li>GST + applicable taxes are charged on the administration fee and/or the claim
                                        amount, dependent on the employee’s province of residence.
                                    </li>
                                    <li>Further details about the actual admin fee can be found within the “Base Plan
                                        Details” section of the Plan Admin portion of the NHC secure web site.
                                    </li>
                                </ul>
                                <p>3. Accounts must be funded within 120 days of claims being processed. Accounts not
                                    funded within 120 days will be placed on hold. Processing of new claims will
                                    automatically resume once funds have been received.</p>
                            </div>

                            <div style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '60%',
                                fontSize: 24,
                                textAlign: 'center',
                                marginTop: 50
                            }}>
                                Schedule “C” <br/>to Administrative Service Indemnity Agreement<br/><br/>
                                <u><b>Account Funding Options and Procedures</b></u>
                            </div>
                            <div className={"my-3 px-5 mt-4"}>
                                <p>1. The HealthCare Spending Account and/or Lifestyle Spending Account can be funded by
                                    the Subscriber in multiple ways with associated claim payment turnaround
                                    performance.</p>
                                <ul>
                                    <li>The fastest claim payment turnaround time is achieved using Pre-Authorized Debit
                                        (PAD). A weekly PAD pull is performed by NHC to top up the funding balance to a
                                        predetermined level. During the time between PAD pulls, claims are paid using
                                        the current account funds. A separate PAD agreement is required to enable this
                                        service.
                                    </li>
                                    <li>Online payment or Direct Deposit options allow the Subscriber to fund their
                                        account as desired through their banking services. Funding credit will be
                                        applied upon notification by NHC’s bank and will be available for immediate use.
                                    </li>
                                    <li>Funding with Wire Transfer is supported and will be available for immediate
                                        use.
                                    </li>
                                    <li>Funding by cheque is supported, however, a 10-day holding period is required to
                                        ensure the funds have cleared.
                                    </li>
                                </ul>
                                <p>2. Subscribers who haven't paid submitted claims for more than 120 days because of
                                    insufficient balance on hand will face a hold on processing any new claims.
                                    Processing of new and held claims will automatically resume once funds have been
                                    received.</p>
                            </div>
                        </div>
                    </div>
                    {
                        mode !== 'edit' && (
                            <div className="footer-submit m-5">
                                <Row className="justify-content-md-center">
                                    <Col xs={12} sm={12} md={8}>
                                        <h5>
                                            <strong>Important</strong>
                                        </h5>
                                        <p></p>
                                        <p>
                                            Please take a moment to review your form for accuracy, paying special
                                            attention to policy details as any inaccuracies may result in delays in
                                            setting up your plan.
                                        </p>
                                        <p>
                                            If you have any questions or require assistance, feel free to visit our{" "}
                                            <a href="https://support.nationalhealthclaim.com/">Support Center</a>,
                                            submit a{" "}
                                            <a href="https://support.nationalhealthclaim.com/hc/en-us/requests/new">
                                                Support Request
                                            </a>
                                            , or call us at 1-866-342-5908. We're here to help.
                                        </p>
                                        <div style={{textAlign: "center", marginTop: "20px"}}>
                                            <Button
                                                variant="success"
                                                type="submit"
                                                style={{width: "100px"}}
                                                size="lg"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        <p></p>
                                    </Col>
                                </Row>
                            </div>

                        )
                    }

                </form>
                <Modal show={showSignatureModal} onHide={() => setShowSignatureModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Adopt Your Signature</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{marginBottom: 15}}>
                            <p>Confirm your name and signature</p><input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            value={signature}
                            onChange={(e) => setSignature(e.target.value)}
                        />
                        </div>
                        <div>
                            <p>Signature</p>
                            <div style={{marginBottom: 10}}>
                                <input type="radio" id="typing" name="signatureMethod" value="typing"
                                       checked={!isDrawing}
                                       onChange={() => setIsDrawing(false)}/>
                                <label htmlFor="typing" className="me-3" style={{marginLeft: 8}}>Type
                                    Signature</label>

                                <input type="radio" id="drawing" name="signatureMethod" value="drawing"
                                       checked={isDrawing}
                                       onChange={() => setIsDrawing(true)}/>
                                <label htmlFor="drawing" style={{marginLeft: 8}}>Draw Signature</label>
                            </div>
                            {isDrawing ? <div>
                                <div style={{marginBottom: 8}}>Hold and drag to draw your signature</div>
                                <div>
                                    <SignatureCanvas ref={sigCanvas}
                                                     canvasProps={{className: 'signatureCanvas'}}/>

                                </div>
                                <div>
                                    <button className="btn btn-primary btn-sm" onClick={saveSignature}
                                            type={"button"}>Save
                                    </button>
                                    <button className="btn btn-outline-secondary btn-sm" type={"button"}
                                            onClick={clearSignature}>Clear
                                    </button>
                                </div>
                            </div> : <div>
                                <div className="signature">{signature}</div>
                            </div>}

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={"button"} variant="secondary"
                                onClick={() => setShowSignatureModal(false)}>
                            Cancel
                        </Button>
                        <Button type={"button"}
                                disabled={(!isDrawing && !signature.trim()) || (isDrawing && !imageURL)}
                                variant="primary btn-success" onClick={() => {
                            setShowSignatureModal(false);
                            setIsSigned(true)
                        }}>
                            Adopt and Sign
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal className={"send-enroll-modal"} show={showSendModal}
                       onHide={() => setShowSendModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Send Enrollment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-section" style={{fontSize: 18}}>
                            <div className={"mb-lg-4"}>
                                All set! Your client will receive an email shortly with a link to finalize their
                                enrollment. Feel free to update their information if you have any changes.
                            </div>
                            <div className="form-section">
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={4}>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(e) => setClientFirstName(e.target.value)}
                                            value={clientFirstName ?? ''}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={4}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            onChange={(e) => setClientLastName(e.target.value)}
                                            value={clientLastName ?? ''}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={4}>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            onChange={(e) => setClientEmail(e.target.value)}
                                            value={clientEmail ?? ''}
                                            required
                                        />
                                    </Form.Group>
                                </Row>
                            </div>
                            <div style={{color: "gray"}}>
                                <div className="icon-text">
                                    <TbFileCheck/>
                                    <div>
                                        After the enrollment form is completed, National HealthClaim (NHC) will be
                                        notified and will begin the review process. If there are any questions, NHC may
                                        reach
                                        out to the company's Plan Administrator or Advisor for further discussion.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowSendModal(false)}>
                            Cancel
                        </Button><Button
                        variant="success"
                        disabled={!clientFirstName || !clientLastName || !clientEmail}
                        size="md"
                        onClick={async (e) => {
                            e.preventDefault();
                            await handleSendForm(false);
                        }}
                        style={{minWidth: '120px'}}
                    >
                        Send
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    </>);
};

export default EnrollmentFormPage;