import {Spinner} from 'react-bootstrap';
import {FaPaperPlane, FaSave} from 'react-icons/fa';
import {IoClose} from "react-icons/io5";
import {RxHamburgerMenu} from "react-icons/rx";
import {useLocation} from 'react-router-dom';

import "./header.styles.scss";
import nhcLogo from "../../assets/nhclogo.png"


const Header = ({onSave, onSend, mode, onClick, showSidebar, saving, sending}) => {
    const location = useLocation();
    const isEnrollPage = location.pathname.includes('/enroll/');
    return (
        <div className="header__container" style={{display: 'flex', justifyContent: 'space-between'}}>
            {!isEnrollPage && (
                <>
                    {showSidebar ? (
                        <IoClose
                            className="menu__logo"
                            color="#223e7f"
                            onClick={onClick}
                            size={32}
                        />
                    ) : (
                        <RxHamburgerMenu
                            className="menu__logo"
                            color="#223e7f"
                            onClick={onClick}
                            size={32}
                        />
                    )}
                </>
            )}

            <div className="logo__container">
                <img className="header__logo" src={nhcLogo} alt="Logo"/>
                {!location.pathname.startsWith('/enroll') && <h4 className="header-title">ADVISOR PORTAL</h4>}
            </div>

            {isEnrollPage && (
                <div className="text-end m-4 pb-2">
                    <button
                        className="btn btn-warning header-save-btn me-3"
                        onClick={onSave}
                        style={{minWidth: 50}}
                    >
                        {saving ? (
                            <Spinner animation="border" size="sm" variant="light"/>
                        ) : (
                            <>
                                <span className="d-none d-sm-inline me-2">Save</span>
                                <FaSave className="d-inline-block"/>
                            </>
                        )}
                    </button>
                    {mode === 'edit' && (
                        <button
                            className="btn btn-success header-send-btn"
                            onClick={onSend}
                            style={{minWidth: 50}}
                        >
                            {sending ? (
                                <Spinner animation="border" size="sm" variant="light"/>
                            ) : (
                                <>
                                    <span className="d-none d-sm-inline me-2">Send</span>
                                    <FaPaperPlane className="d-inline-block"/>
                                </>
                            )}
                        </button>
                    )}
                </div>

            )}
        </div>
    );
};

export default Header;
