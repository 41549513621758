import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {setCurrentDistro} from "../../../redux/distro/distro.actions";

import {selectCurrentUser} from "../../../redux/user/user.selectors";

import {SectionWrapper} from "../../section-wrapper/sectionWrapper.styles";
import InfoItem from "../info-item/info-item.component";

import "./account-info.styles.scss";

const AccountInfoOwner = ({info, currentUser, setCurrentDistro}) => (
    <SectionWrapper title={"Account Information"}>
        <div className="container-fluid py-4">
            <div className="row g-4">
                {/* Account Details Column */}
                <div className="col-12 col-md-6">
                    <div className="card h-100 shadow-sm">
                        <div className="card-header bg-primary text-white">
                            <h5 className="card-title mb-0">Account Details</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                            <InfoItem label="Name" value={info.name}/>
                            <InfoItem label="Description" value={info.description}/>
                            <InfoItem label="Account Id" value={info.accountId}/>
                            <InfoItem label="Distro Id" value={info.distroId}/>
                            <InfoItem label="Contact Email" value={info.email}/>
                            <InfoItem label="Phone" value={info.phone}/>
                            <InfoItem label="Eft" value={info.eft}/>
                        </ul>
                    </div>
                </div>

                {/* Address Column */}
                <div className="col-12 col-md-6">
                    <div className="card h-100 shadow-sm">
                        <div className="card-header bg-secondary text-white">
                            <h5 className="card-title mb-0">Address</h5>
                        </div>
                        <ul className="list-group list-group-flush">
                            <InfoItem label="Address 1" value={info.mailingStreet1}/>
                            <InfoItem label="Address 2" value={info.mailingStreet2}/>
                            <InfoItem label="City" value={info.mailingCity}/>
                            <InfoItem label="Province" value={info.mailingProv}/>
                            <InfoItem label="Postal Code" value={info.mailingPostalCode}/>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        {currentUser.distros.length > 1 ? (
            <Button
                className="px-4 distroBtn"
                variant="primary"
                onClick={() => setCurrentDistro(null)}
            >
                Change Account
            </Button>
        ) : null}
    </SectionWrapper>
);

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentDistro: (data) => dispatch(setCurrentDistro(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfoOwner);
