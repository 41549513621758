import {Link} from "react-router-dom";
import CommissionsTable from "../../components/commissions/commisions.component";

import {SectionWrapper} from "../../components/section-wrapper/sectionWrapper.styles";

const ClientsCard = ({commissions}) => {
    const sortCommission = (commissions) => {
        return commissions.sort((a, b) => {
            return b.amount - a.amount;
        });
    };

    return (
        <SectionWrapper title={"Client Commissions"} fullWidth={true} card={false}>
            <div className="section__container">
                <div className="tables__container">
                    {commissions.length > 0 ? (
                        <CommissionsTable
                            commissions={sortCommission(commissions).slice(0, 3)}
                            title={"Top Earning Breakdown (Last Month)"}
                        />
                    ) : null}
                </div>
            </div>
            <Link to={"/user/clients?type=policy"}>View Client List</Link>
        </SectionWrapper>
    );
};

export default ClientsCard;
