import {Switch, Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import EnrollmentsDashboardPageComponent from "./pages/reports-pages/enrollments-dashboard-page.component";

import SignInPage from "./pages/sign-in/sign-in-page.component";
import SetPasswordPage from "./pages/sign-in/set-password.component";
import ResetPasswordPage from "./pages/sign-in/reset-password.component";
import PageNotFound from "./pages/404/404-page.component";

import DashBoardPage from "./pages/reports-pages/dash-page.component";
import FinancialsPage from "./pages/reports-pages/financials-page.component";
import DistributionPage from "./pages/reports-pages/distribution-page.component";
import AdvisorsPage from "./pages/reports-pages/advisors-page.component";
import ClientsSection from "./pages/reports-pages/clients-page.component,";
import CompaniesPage from "./pages/reports-pages/companies-page.component";
import UserProfile from "./pages/reports-pages/user-profile.component";
import InvalidTokenPage from "./pages/sign-in/invalid-token-page.component";
import EnrollmentsDashboardPage from "./pages/reports-pages/enrollments-dashboard-page.component";
import EnrollmentFormPage from "./pages/reports-pages/enrollment-form-page.component";

import {setCurrentUser} from "./redux/user/user.actions";
import {selectCurrentUser} from "./redux/user/user.selectors";

function App({currentUser}) {
    return (
        <div>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/signin"/>
                </Route>
                <Route exact path={"/signin"} component={SignInPage}/>
                <Route exact path="/resetpassword" component={ResetPasswordPage}/>
                <Route exact path={"/invalid"} component={InvalidTokenPage}/>
                <Route exact path={"/enroll/:id"} component={EnrollmentFormPage}/>
                <Route
                    exact
                    path={`/setpassword/:token`}
                    component={SetPasswordPage}
                />

                <Route
                    exact
                    path={`/user/dashboard`}
                    render={() =>
                        !currentUser ? <Redirect to="/signin"/> : <DashBoardPage/>
                    }
                />

                <Route
                    exact
                    path={`/user/financials`}
                    render={() =>
                        !currentUser ? <Redirect to="/signin"/> : <FinancialsPage/>
                    }
                />

                <Route
                    exact
                    path={`/user/commission`}
                    render={() =>
                        !currentUser ? <Redirect to="/signin"/> : <DistributionPage/>
                    }
                />

                <Route
                    exact
                    path={`/user/enrollments`}
                    render={() =>
                        !currentUser ? <Redirect to="/signin"/> : <EnrollmentsDashboardPageComponent/>
                    }
                />

                <Route
                    exact
                    path={`/user/clients/:type?`}
                    render={({ match }) =>
                        !currentUser ? <Redirect to="/signin"/> : <ClientsSection type={match.params.type} />
                    }
                />

                <Route
                    exact
                    path={`/user/profile`}
                    render={() =>
                        !currentUser ? <Redirect to="/signin"/> : <UserProfile/>
                    }
                />

                <Route
                    exact
                    path={`/user/advisors`}
                    render={() =>
                        !currentUser ? <Redirect to="/signin"/> : <AdvisorsPage/>
                    }
                />

                <Route
                    exact
                    path={`/user/companies`}
                    render={() =>
                        !currentUser ? <Redirect to="/signin"/> : <CompaniesPage/>
                    }
                />

                <Route component={PageNotFound}/>
            </Switch>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
