const SummaryTable = ({summary}) => (
    <div className="responsive__table__container">
        <h2 className="header__title">Total Summary</h2>
        <table className="table">
            <thead>
            <tr>
                <th scope="col">Type</th>
                <th scope="col" className="text-right">
                    Amount
                </th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            {summary.length > 0
                ? summary.map((item) => {
                    return (
                        <tr key={item.type + item.amount}>
                            <th scope="row" key={item.type}>
                                {item.type}
                            </th>
                            <td key={item.amount} className="text-right">
                                ${parseFloat(item.amount).toFixed(2)}
                            </td>
                            <td></td>
                        </tr>
                    );
                })
                : null}
            </tbody>
        </table>
    </div>
);

export default SummaryTable;
